import React, {useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation} from "react-router-dom";
import {Navbar, Footer} from './components'
import {useAuth} from './context'
import {Home, Apply, Explore, Talent, Profile, Order, Login, Signup, Payment, Invite} from './pages'


const BaseRoute = ({ children, ...rest }: any) => {
  const {setUrlPath} = useAuth()
  setUrlPath(window.location.pathname)
    return (
      <>
      <Navbar />
      <Route
        {...rest}
        render={(props: any) => children}
      />
      <Footer />
      </>
    );
}

const PrivateRoute = ({ children, ...rest }: any) => {
  const {isAuth, setUrlPath} = useAuth()
  setUrlPath(window.location.pathname)
    return (
      <>
        <Navbar />
         <Route
          {...rest}
          render={(props) => isAuth ? (
                    children
                  ) : (
                    <Redirect to= "/login"/>
                  )
                }
        />
        <Footer />
      </>
    );
}

const AuthRoute = ({ component: Component, ...rest }: any) => {
  const {isAuth, urlPath} = useAuth()
    return (
      <>
        <Navbar />
        <Route
          {...rest}
          render={(props) => !isAuth ? 
            <Component {...props} /> : 
            <Redirect to={urlPath || "/"}/>
          }
        />
        <Footer />
      </>
    );
}

const BackToTop = () => {
  const urlPath = useLocation()
  useEffect(() => {
    (()=> window.scrollTo(0,0))()
  }, [urlPath])

  return null
}

const Approutes = () => {
  console.log(window.location.pathname)
    return (
        <Router>
            <BackToTop />
            <Switch>
              <Route path="/" exact > <Home /> </Route>
              <Route path="/payment/:requestId"  component={Payment}/>
              <BaseRoute path="/celebrity/:id" exact> <Talent /> </BaseRoute>
              <BaseRoute path="/explore" component={Explore} />
              <BaseRoute path="/apply" component={Apply} />
              <AuthRoute path="/login" component={Login} />
              <AuthRoute path="/signup" component={Signup} />
              {/* <PrivateRoute path="/profile"  component={Profile} />
              <PrivateRoute path="/request/:celebrityId"  component={Order} />
              <PrivateRoute path="/invite"  component={Invite} /> */}
              <PrivateRoute path="/profile"> <Profile /> </ PrivateRoute>
              <PrivateRoute path="/request/:celebrityId"> <Order /> </ PrivateRoute>
              <PrivateRoute path="/invite"> <Invite /> </ PrivateRoute>
            </Switch>
        </Router>
    )
}

export default Approutes
