import React, {useState} from 'react'
import { useRouteMatch, Redirect } from "react-router-dom";
import styled from 'styled-components'
import {useQuery } from 'react-query'
import {fetchTalents} from '../../module'
import { Loader } from '../../components';
import Profile from './talentComponents/profile'
import Videos from './talentComponents/videos'
import Reviews from './talentComponents/reviews'

const TalentContainer = styled.div<{isVideo: boolean}>`
  background-color: ${({theme}) => theme.colors.background};
  display: flex;
  flex-direction: column;
  .switch{
      height: 30px;
      margin: 0 50px 50px;
      display: flex;
    .video,.review{
        height: 100%;
        width: 100%;
        display: flex;
        cursor: pointer;
        p{
        padding: 5px 0 0;
          color: ${({theme}) => theme.colors.white};
          font-size: 16px;
          font-weight: 500;
          border-top: thin solid ${({theme}) => theme.colors.white};
        }
    }
    .video{
      justify-content: flex-end;
      padding-right: 40px;
      p{
          border-top: ${({isVideo}) => !isVideo && "none"};
      }
    }
    .review{
        justify-content: flex-start;
        padding-left: 40px;
        p{
          border-top: ${({isVideo}) => isVideo && "none"};
      }
    }
    @media (max-width: 767px){
      .video,.review{
        p{
          font-size: 14px;
        }
      }
      .video{
        padding-right: 20px;
      }
      .review{
        padding-left: 20px;
      }
    }
  }
`;


const Talent = () => {
    const match = useRouteMatch("/celebrity/:id");
    const { isLoading, error, data} = useQuery('fetchTalents', fetchTalents)
    const [showVideos, setShowVideos] = useState(true);

  if(isLoading || error) return <Loader />

  if(!match) return <Redirect to="/explore" />
  const talentDetails = data.find((talent:any) => match.url.split("/")[2] === `${talent.First_name}${talent.Last_name}`)
    return (
        <>
          {
            talentDetails ? (
            <TalentContainer isVideo={showVideos}>
                <Profile details={talentDetails}/>
                <div className="switch">
                    <div className="video" onClick={() => setShowVideos(true)}>
                        <p>{`${talentDetails.First_name}’s Videos`}</p>
                    </div>
                    <div className="review" onClick={() => setShowVideos(false)}>
                        <p>{`${talentDetails.First_name}’s Reviews`}</p>
                    </div>
                </div>
                {
                    showVideos ? (<Videos celebId={talentDetails._id}/>) : (<Reviews celebId={talentDetails._id}/>)
                }
            </TalentContainer>
            ) : (
              <Redirect to="/explore" />
            )
          }
        </>
    )
}

export default Talent
