import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { useForm, SubmitHandler } from "react-hook-form";
import { useRouteMatch, Redirect } from "react-router-dom";
import {useQuery } from 'react-query'
import { NavLink } from 'react-router-dom';
import { RequestPictureUpload, BreadCrumbStyle, Loader } from '../../components'
import {fetchUserProfile, fetchTalents} from '../../module'
import {Wrap} from './orderstyle'
import { useAuth } from '../../context'
import HowTialalaWorks from './orderComponents/howTialalaWorks'
import Summary from './orderComponents/summary'

const OrderContainer = styled.div<{whois:string, showSummary: boolean}>`
  background-color: ${({theme}) => theme.colors.background};
  padding: 30px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1023px){
    padding: 30px 1vw;
  }
  .allTop{
    width: 100%;
    max-width: 900px;
  }
  .orderTop{
      background-color: ${({theme}) => theme.colors.newPurple};
      border-radius: 10px 10px 0 0;
      width: 100%;
      max-width: 900px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0 40px;
      h1.summary{
        color: ${({theme}) => theme.colors.white};
        font-size: 40px;
        font-weight: 400;
        display: ${({showSummary}) => showSummary ? 'block' : 'none'};
      }
      .friend, .self{
        width: 160px;
        cursor: pointer;
        display: ${({showSummary}) => showSummary ? 'none' : 'flex'};
        justify-content: center;
        align-items: center;
        p{
          font-size: 16px;
          font-weight: 700;
          padding: 12px 0;
          text-align: center;
        }
      }
      .friend{
        background-color: ${({whois, theme}) => whois === "friend"  ? theme.colors.white : "transparent" };
        border: ${({whois}) => whois !== "friend"  ? "1px solid #434343" : "none" };
        p{
            color: ${({whois, theme}) => whois === "friend"  ? theme.colors.lilac : theme.colors.white };
        }
      }
      .self{
        background-color: ${({whois, theme}) => whois === "self"  ? theme.colors.white : "transparent" };
        border: ${({whois}) => whois !== "me"  ? "1px solid #434343" : "none" };
        p{
            color: ${({whois, theme}) => whois === "self"  ? theme.colors.lilac : theme.colors.white };
        }
      }
  }
  .body{
    background-color: ${props => props.theme.colors.newPurple};
    border-radius: 0 0 10px 10px;
    width: 100%;
    max-width: 900px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .formSpace{
        width: 60%;
        max-width: 660px;
        margin-right: 70px;
    }
}
@media only screen and (max-width: 1023px){
  .orderTop{
    padding: 30px 0 10px;
    h1.summary{
        font-size: 28px;
      }
  }
  .body{
    flex-direction: column;
    padding: 0 0 10vw;
      .formSpace{
        width: 100%;
        margin-right: 0;
      }
  }
}
  // padding: 60px 0 10vw;
`;

type formInputs = {
  [key: string]: string;
};

const Order = () => {
    const match = useRouteMatch("/request/:celebrityId");
    const {logout} = useAuth()
    const { isLoading, error, data} = useQuery('fetchTalents', fetchTalents)
    const { isLoading: profileLoading, error: profileError, data: profileData } = useQuery('fetchUserProfile', () => fetchUserProfile(logout))

    const [forWho, setForWho] = useState<"friend" | "self">("friend")
    const [showSummary, setShowSummary] = useState(false)
    const [userRequest, setUserRequestData] = useState({})
    const [requestPictureUrl, setRequestPictureUrl] = useState('')
    const { register, handleSubmit, watch, formState: { errors } } = useForm<formInputs>();

    useEffect(() => {
      (()=> window.scrollTo(0,0))()
    }, [showSummary])

    if(!match) return <Redirect to="/explore" />
    if(isLoading || profileLoading || error|| profileError) return <Loader />
    
    const talentDetails = data.find((talent:any) => match.url.split("/")[2] === `${talent.First_name}${talent.Last_name}`)
    
    const requestCount = watch("instruction");

    const onSubmit: SubmitHandler<formInputs> = (formData) => {
      console.log(formData)
      setUserRequestData({
        ...formData,
        pics: requestPictureUrl,
        talentFirstName: talentDetails.First_name,
        talentLastName: talentDetails.Last_name,
        response_time: talentDetails.response_time,
        type: forWho,
        celebrityId: talentDetails._id,
        amount: parseFloat(talentDetails.video_price),
        recordLink: 'null',
      })
      setShowSummary(true)
    };
  
    
    console.log(talentDetails)
    console.log(profileData)

    return (
      <>
        <OrderContainer whois={forWho} showSummary={showSummary}>
        <div className="allTop">
          <BreadCrumbStyle>
            <NavLink to="/">Home</NavLink>
            <i className="large material-icons">navigate_next</i>
            <NavLink to="/explore">Celebrities</NavLink>
            <i className="large material-icons">navigate_next</i>
            <NavLink to={`/celebrity/${talentDetails.First_name.trim()}${talentDetails.Last_name.trim()}`}>Request to {talentDetails.First_name} {talentDetails.Last_name}</NavLink>
          </BreadCrumbStyle>
          <div className="orderTop">
              <div className="friend" onClick={() => setForWho("friend")}>
                  <p>For a friend</p>
              </div>
              <div className="self" onClick={() => setForWho("self")}>
                  <p>For me</p>
              </div>
              <h1 className="summary">Summary</h1>
          </div>
        </div>
          <div className="body">
              <div className="formSpace">
                {
                  showSummary ? (
                    <Summary requestData={userRequest}/>
                  ) : (
                    <Wrap>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        {
                          forWho === 'friend' && (
                            <>
                              <RequestPictureUpload
                                requestPictureUrl={requestPictureUrl}
                                setRequestPictureUrl={setRequestPictureUrl}
                              />
                              <div className="unit">
                                <label>From</label>
                                <div className="inner">
                                  <input type='text'  placeholder='me' {...register('from', { required: true })}/>
                                </div>
                                {errors.from && <span>required</span>}
                              </div>
                            </>
                          )
                        }
                        <div className="unit">
                          <label>To</label>
                          <div className="inner">
                          <input type='text'  placeholder='friend' {...register('to', { required: true })}/>
                          </div>
                          {errors.to && <span>required</span>}
                        </div>
                        <div className="unit">
                          <label>Pronoun</label>
                          <div className="inner">
                          <select {...register("pronoun", { required: true })}>
                            <option value="">Choose a pronoun</option>
                            <option value="He/Him">He/Him</option>
                            <option value="She/Her">She/Her</option>
                            <option value="They/Them">They/Them</option>
                          </select>
                          </div>
                          {errors.pronoun && <span>required</span>}
                        </div>
                        <div className="unit">
                          <label>Occasion</label>
                          <div className="inner">
                          <select {...register("occasion", { required: true })}>
                            <option value="">Choose an occasion</option>
                            <option value="Birthday" >Birthday</option>
                            <option value="Celebration" >Celebration</option>
                            <option value="Give thanks" >Give thanks</option>
                            <option value="Ask a question" >Ask a question</option>
                            <option value="Get Advice" >Get Advice</option>
                            <option value="Anniversary" >Anniversary</option>
                            <option value="Just Because" >Just Because</option>
                          </select>
                          </div>
                          {errors.occasion && <span>required</span>}
                        </div>
                        <div className="unit">
                          <label>Instructions <i>({requestCount?.length || 0}/250)</i> </label>
                          <div className="inner innerNoPadding">
                          <textarea cols={30} placeholder='Type your request...' maxLength={250} {...register('instruction', { required: true })}></textarea>
                          </div>
                          {errors.instruction && <span>not more than 250 words</span>}
                        </div>
                        <input type="submit" value="Continue" />
                      </form>
                    </Wrap>
                  )
                }
              </div>
              <HowTialalaWorks />
          </div>
        </OrderContainer>
      </>
    )
}

export default Order
