import React, { useState } from 'react'
import S3 from 'react-aws-s3';
import { useQueryClient } from 'react-query'
import styled from 'styled-components';
import { baseApi } from '../module';
import {Spinner} from '.'
// import userAvatar from '../assets/user_avatar.png'
import userAvatar from '../assets/logo2.png'

const updateProfilePictureLink = async (location: string) => {
    const token = window.localStorage.getItem("token")
    if(token){
        const response = await baseApi.post('/settings/update/profile/pics', {location}, {
            headers: {Authentication: `Bearer ${JSON.parse(token)}`}
        })
        console.log(response.status)
        console.log(response.data)
        return response
    }
};


const FormContainer = styled.div`
  width: 100%;
  padding: 20px 0 0;
  background-color: transparent;
  /* box-shadow: 0px 0.866907px 20.8058px -0.866907px rgba(51, 51, 51, 0.1); */
  .picture{
      display: flex;
      justify-content: center;
      align-items: center;
    .pictureCenter{
        width: 100px;
        height: 100px;
        position: relative;
        img{
            width: 100px;
            height: 100px;
            border-radius: 50px;
            border: 5px solid white;
            object-fit: cover;
        }
        label.pictureInput{
          background-image: linear-gradient(52.96deg, #3F7DFE 10.7%, #B183FF 92%);
          padding: 0 5px;
          border-radius: 10px;
          position: absolute;
          bottom: 0;
          right: 0;
          cursor: pointer;
          i{
            color: ${({theme}) => theme.colors.white};
            font-size: 12px;
          }
        }
        input[type=file]{
          display: none;
        }
    }
  }
`;

const s3Config = {
    bucketName: 'tialala',
    dirName: 'test',
    region: 'us-east-2',
    accessKeyId: 'AKIAYJRVAC6BV4ZKRZEA',
    secretAccessKey: '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',
}

export const ProfilePictureUpload = ({profileImageUrl}: {profileImageUrl?: string}) => {

    const [isloading, setIsloading] = useState(false)
    const queryClient = useQueryClient()

const ReactS3Client = new S3(s3Config);

const uploadPicture = (e:any) => {
    console.log('upload start')
    setIsloading(true)
    ReactS3Client
        .uploadFile(e.target.files[0], 'newFileName')
        .then((data: any) => { //data.location // data.status === 204
          console.log({data})
          return data.location
        })
        .then((location: string) => {
            updateProfilePictureLink(location)
            queryClient.invalidateQueries('fetchUserProfile', { exact: true })
        })
        .catch((err: any) => console.error({err}))
        .finally(() => setIsloading(false))
}

    return (
        <FormContainer>
            <div className="picture">
                {
                    isloading ? (
                        <Spinner size={50}/>
                    ) : (
                            <div className="pictureCenter">
                                <img src={profileImageUrl || userAvatar} alt="" />
                                <label className="pictureInput" htmlFor="pix">
                                <i className="material-icons">photo_camera</i>
                                </label>
                                <input type="file" id="pix" onChange={uploadPicture}/>
                            </div>
                    )
                }
            </div>
        </FormContainer>
    )
}



export const RequestPictureUpload = ({requestPictureUrl, setRequestPictureUrl}: {requestPictureUrl: string, setRequestPictureUrl: any}) => {

    const [isloading, setIsloading] = useState(false)

const ReactS3Client = new S3(s3Config);

const uploadPicture = (e:any) => {
    console.log('upload start')
    setIsloading(true)
    ReactS3Client
        .uploadFile(e.target.files[0], 'request_image')
        .then((data: any) => {
          console.log({data})
          return data.location
        })
        .then((location: string) => {
            setRequestPictureUrl(location)
        })
        .catch((err: any) => console.error({err}))
        .finally(() => setIsloading(false))
}

    return (
        <FormContainer>
            <div className="picture">
                {
                   isloading ? (
                    <Spinner size={50}/>
                ) : (
                    <div className="pictureCenter">
                        <img src={requestPictureUrl || userAvatar} alt="" />
                        <label className="pictureInput" htmlFor="pix">
                        <i className="material-icons">photo_camera</i>
                        </label>
                        <input type="file" id="pix" onChange={uploadPicture}/>
                    </div>
                ) 
                }
            </div>
        </FormContainer>
    )
}