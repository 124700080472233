import {baseApi} from '.'

export const fetchTalents = async () => {
    try {
       const {data} = await baseApi('/home/get/celebrities')
       return data.data
       
   } catch (error) {
     return error  
   }
}