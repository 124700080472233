import axios from "axios";
import { useAuth } from "../context";

//const baseURL = 'https://tialala-q5r2.onrender.com/api/users/'
const baseURL = "https://api.tialala.com/api/users";

export const baseApi = axios.create({
  baseURL,
  validateStatus: (status) => status < 500,
});

export const authApi = axios.create({
  baseURL,
  validateStatus: (status) => status < 500,
});
