import { useState, useEffect } from "react";
import styled from 'styled-components';
import {useQuery } from 'react-query'
import { useAuth } from "../../context";
import {fetchTalents, fetchCategories} from '../../module'
import Main from './homeComponents/main';
import HowItWorks from './homeComponents/howItWorks'
import TalentReg from './homeComponents/talentReg'
import {GroupOfCards, Navbar, Footer, Loader} from '../../components'

const HomeContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.background};
`;

export default function Home() {
  const { isLoading: talentLoading, error: talentError, data: talentData } = useQuery('fetchTalents', fetchTalents);
  const { isLoading: categoryLoading, error: categoryError, data: categoryData } = useQuery('fetchCategories', fetchCategories);
  const [isTransparent, setIsTransparent] = useState(true);
  const [navPosition, setNavPosition] = useState<'absolute' | 'fixed'>('absolute')
  const {setUrlPath} = useAuth()
  setUrlPath(window.location.pathname)

  const changeTransparent = () => {
    const width = window.innerWidth
    const pointOfChange = width < 768 ? width * 925 / 415 : width * 713 / 1440
    if(window.scrollY >= pointOfChange) {
      setIsTransparent(false)
      setNavPosition('fixed')
    }else{
      setIsTransparent(true)
      setNavPosition('absolute')
    }
    
  }

  useEffect(() => {
    window.addEventListener('scroll', changeTransparent)
    return () => window.removeEventListener('scroll', changeTransparent)
  }, [window.scrollY])
  return (
    <>
    <HomeContainer>
      <Navbar isTransparent={isTransparent} navPosition={navPosition}/>
      <Main/>
      {
        (talentLoading || categoryLoading || talentError || categoryError) ? (<Loader />) : (
          <>
            <GroupOfCards
              title={categoryData[0]}
              link={categoryData[0]}
              cardInfo={talentData}/>
            <GroupOfCards
              title={categoryData[1]}
              link={categoryData[1]}
              cardInfo={talentData}/> 
          </>
        )
      }
        <HowItWorks />
      {
        (talentLoading || categoryLoading || talentError || categoryError) ? (<Loader />) :
        categoryData.slice(2).map((category: string) => (
          <GroupOfCards
          title={category}
          link={category}
          cardInfo={talentData}/>
        ))
      }
        <TalentReg />
      <Footer />
    </HomeContainer>
    </>
  )
}
