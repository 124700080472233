import React from 'react'
import Loader from "react-loader-spinner";

const Spinner = ({size}:{size?: number }) => {
    return (
      <Loader
        type="TailSpin"
        color="#FFF"
        height={size || 20}
        width={size || 20}
        timeout={99999}
      />
    );
}

export default Spinner