import styled from 'styled-components';
import {NavLink } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Carousel1 from '../../../assets/carousels/carousel1.png';
import Carousel2 from '../../../assets/carousels/carousel2.png';
import Carousel3 from '../../../assets/carousels/carousel3.png';
import MobileCarousel1 from '../../../assets/carousels/mobile-carousel1.png';
import MobileCarousel2 from '../../../assets/carousels/mobile-carousel2.png';
import MobileCarousel3 from '../../../assets/carousels/mobile-carousel3.png';


const MainContainer =styled.main`
  width: 100%;
  margin: 0 0 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%);
  .axis-vertical {
    display: none;
  }
`;

const ContentContainer =styled.div<{number:number}>`
    height: 100vh;
    .padder{
        display: flex;
        flex-direction: column-reverse;
        padding: 50px 4vw 20px;
        align-items: center;
        background-image: url(${({number}) => number === 1 ?  MobileCarousel1 : number === 2 ?  MobileCarousel2 : MobileCarousel3});
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        width: 100vw;
        height: 100vh;
        background-position: 0 calc( ((100vw * 920 / 414) - 100vh) * -1 );
      .text{
        /* position: absolute; */
        width: 80%;
        h1, p{
            color: ${(props) => props.theme.colors.white};
            text-align: center;
        }
        h1{
            font-size: 40px;
            line-height: 54px;
            font-weight: 800;
        }
        p{
            font-size: 18px;
            line-height: 30px;
            font-weight: 400;
            padding: 2vw 0 2.5vw;
        }
        a{
            background-color: ${(props) => props.theme.colors.white};
            color: ${(props) => props.theme.colors.purple};
            display: inline-block;
            width: fit-content;
            margin: 15px 0 25px;
            padding: 20px 30px;
            font-weight: 700;
            font-size: 16px;
            line-height: 13px;
            border-radius: .5vw;
        }
      }
      .pics{
          width: 80%;
          margin: 0 0 50px;
      }
    }
    @media only screen and (min-width: 768px) {
      .padder{
        flex-direction: row;
        justify-content: space-between;
        background-image: url(${({number}) => number === 1 ?  Carousel1 : number === 2 ?  Carousel2 : Carousel3});
        /* aspect-ratio: 1440 / 713; */
        width: 100%;
        height: calc(100vw * 713 / 1440);
        background-position: 0 0;
        .text{
          width: 50%;
          display: flex;
          flex-direction: column;
        h1, p{
          text-align: start;
        }
        h1{
            /* font-size: 7vw;
            line-height: 7vw; */
            font-size: 56px;
            line-height: 58px;
            font-weight: 700;
            width: 560px;
        }
        p{
            /* font-size: 1.8vw;
            line-height: 2.7vw; */
            font-size: 24px;
            line-height: 38px;
            font-weight: 400;
            /* padding: 2vw 0 2.5vw; */
        }
        a{
            background-color: ${(props) => props.theme.colors.white};
            color: ${(props) => props.theme.colors.purple};
            display: inline-block;
            width: fit-content;
            margin: 0;
            padding: 17px 32px;
            font-weight: 700;
            /* font-size: 1.5vw;
            line-height: 1.5vw; */
            font-size: 18px;
            line-height: 15px;
            border-radius: 8px;
        }
        }
        .pics{
          width: 40%;
        }
      }
    }
`;

type ContentProps = {
    title: string,
    body: string,
    number: number,
  };

const Content = ({title, body, number}:ContentProps) => {
    return (
      <ContentContainer number={number}>
        <div className="padder">
            <div className="text">
                <h1>{title}</h1>
                <p>{body}</p>
                <NavLink to="/explore">All Categories</NavLink>
            </div>
            <div className="pics">
            </div>
        </div>
      </ContentContainer>
    )
  }

// const SvgPath = () => {
//     return (
//       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 7 23 2">
//         <path d="M0 8c4-2 9 0 20 0 1 0 2 0 3-1v2H0V8" fill="#0E0123" />
//       </svg>
//     )
// }

const Main = () => {
  return (
    <MainContainer>
        <Carousel
          showArrows={false}
          showStatus={false}
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
        >
            <Content
              title="Personalized videos"
              body="Surprise your friend with a personalized video from their favorite star"
              number={1}
            />
            <Content
              title="Stars for celebration"
              body="Memorable moments deserve once in a lifetime gifts. Check out these stars perfect for celebrating the people you love during important times!!!"
              number={2}
            />
            <Content
              title="Private chats with your favs"
              body="Take your relationship with your favourite star to the next level. Have your idol in your dm"
              number={3}
            />
        </Carousel>
    </MainContainer>
  )
}

export default Main