import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useFetchTalentDetails } from '../../../module'
import { Loader } from '../../../components';
import { RatingView } from 'react-simple-star-rating'

const ReviewContainer = styled.div`
  margin: 0 50px 25px;
  background-color: #150135;
  color: ${({theme}) => theme.colors.white};
  .review{
    display: flex;
    padding: 40px;
    align-items: center;
    .id{
      margin: 0 30px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        height: 50px;
        width: 50px;
        border-radius: 40px;
        font-size: 24px;
        line-height: 50px;
        vertical-align: center;
        text-align: center;
        background: linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%);
      }
    }
    .details{
      width: 100%;
      .extras{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .ratingStar{
            display: flex;
            flex-direction: row;
            align-items: center;
           p.ratingValue{
               font-size: 20px;
               margin-left: 5px;
           } 
        }
        p.ratingDate{
            font-weight: 300;
            font-size: 12px;
        }
      }
      p.comment{
        font-size: 14px;
        font-weight: 400;
        margin: 20px 0 0;
      }
    }
  }
  @media (max-width: 767px){
    margin: 0 10px 25px;
      .review{
        padding: 20px;
          .id{
            p{
              height: 40px;
              width: 40px;
              border-radius: 20px;
              font-size: 20px;
              line-height: 40px;
            }
          }
          .details{
            .extras{
                .ratingStar{
                  p.ratingValue{
                    font-size: 12px;
                    margin-left: 5px;
                  } 
                }
                p.ratingDate{
                    font-weight: 300;
                    font-size: 8px;
                }
            }
            p.comment{
                font-size: 10px;
                font-weight: 400;
                margin: 20px 0 0;
            }
          }
      }
  }
`;

const Reviews = ({celebId}:{celebId: string}) => {
  const {response, error, loading} = useFetchTalentDetails(celebId)
  dayjs.extend(relativeTime)
   if(loading || error) return <Loader />
    return (
        <>
          {
            response.data.data.map((review_: any) => {
                const {from, rating, created, review} = review_;
                return(
                    <ReviewContainer>
                        <div className="review">
                            <div className="id">
                                <p>{from.slice(0,1)}</p>
                            </div>
                            <div className="details">
                            <div className="extras">
                                <div className="ratingStar">
                                    <RatingView ratingValue={parseInt(rating)} size={12} fillColor="#FFC833"/>
                                    <p className="ratingValue">{rating}</p>
                                </div>
                                <p className="ratingDate">{dayjs().to(dayjs(created))}</p>
                            </div>
                                <p className="comment">{review}</p>
                            </div>
                        </div>
                    </ReviewContainer>
                )
            })
          }
        </>
    )
}

export default Reviews
