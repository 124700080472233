import React, {useState} from 'react'
import {useQuery} from 'react-query'
import { useForm, SubmitHandler } from "react-hook-form";
import { useToasts } from 'react-toast-notifications';
import { useAuth } from '../../context'
import { fetchUserProfile, baseApi } from '../../module'
import { ProfilePictureUpload, Spinner, Loader} from '../../components'
import {WrapStyle} from './Style'


type formInputs = {
  [key: string]: string;
};


const Profile = () => {
  const {logout} = useAuth()
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false)
  const { isLoading: fetchLoading, error, data } = useQuery('fetchUserProfile', () => fetchUserProfile(logout))
  const { register, handleSubmit, watch, formState: { errors } } = useForm<formInputs>();
  const onSubmit: SubmitHandler<formInputs> = async (formData) => {
    const token = window.localStorage.getItem("token")
    setIsLoading(true)
    try {
         if(token){
            const {data} = await baseApi.post('/settings/update/profile', formData, {
             headers: {Authentication: `Bearer ${JSON.parse(token)}`}
            })
            console.log(data.message)
            if(data.message === "Profile updated Successfully"){
              addToast('Profile updated', { appearance: 'success' });
            }else{
              addToast('Profile not updated', { appearance: 'error' });
            }
         }else{
            logout()
         }
      } catch (error) {
        console.log(error)
      }finally{
        setIsLoading(false)
      }
  };

  const userBio = watch("info");

  if(fetchLoading || error) return (<Loader />)
    return (
        <WrapStyle>
          <div className="formSpace">
            <ProfilePictureUpload profileImageUrl={data.profilePics}/>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="unit">
                <label>First Name</label>
                <div className="inner">
                <input type='text' defaultValue={data.firstName} placeholder='dave' {...register('firstName', { required: true })}/>
                </div>
                {errors.firstName && <span>required</span>}
              </div>
              <div className="unit">
                <label>Last Name</label>
                <div className="inner">
                  <input type='text' defaultValue={data.lastName} placeholder='dave' {...register('lastName', { required: true })}/>
                </div>
                {errors.lastName && <span>required</span>}
              </div>
              <div className="unit"> 
                <label>Bio <i>({userBio?.length || 0}/256)</i> </label>
                <div className="inner">
                <textarea cols={60} defaultValue={data.info} maxLength={250} placeholder='Tell us a little about you' {...register('info', { required: true })}></textarea>
                </div>
                {errors.info && <span>required</span>}
              </div>
              <div className="unit">
                <label>BirthDay</label>
                <div className="inner">
                {/* <input type='date' defaultValue={data.dob} {...register('dob', { required: true })}/> */}
                <input type='text' value={data.dob} {...register('dob', { required: true })}/>
                </div>
                {errors.dob && <span>required</span>}
              </div>
              {
                !isLoading ? (<input type="submit" value="Save"/>) : (
                  <button>
                    <Spinner />
                  </button>
                )
              }
            </form>
            
          </div>
        </WrapStyle>
    )
}

export default Profile
