import React from 'react'
import styled from "styled-components";



export const WrapStyle = styled.div`
  background-color: ${({theme}) => theme.colors.background};
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .formSpace{
    background-color: ${props => props.theme.colors.newPurple};
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 500px;
    form{
        padding: 20px;
        margin: 20px 0 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .unit{
            width: 100%;
            margin: 0 0 15px;
            label{
                color: ${props => props.theme.colors.white};
                margin: 0 0 20px;
                font-size: 14px;
                i{
                  font-size: 10px;
                }
            }
            .inner{
                border: thin solid  ${props => props.theme.colors.greyD7};
                border-radius: 10px;
                padding: 0;
                margin: 5px 0 0;
                display: flex;
                align-items: center;
                input, textarea{
                background-color: transparent;
                padding: 0 20px;
                border: none;
                height: 50px;
                width: 100%;
                color: ${props => props.theme.colors.white};
                border-radius: 15px;
                font-size: 14px;
                }
                textarea{
                  height: 120px;
                  padding: 5px 20px 0;
                  &::-webkit-scrollbar {
                    display: none;
                  }
                }
            }
            span{
                color: red;
                font-size: 10px;
                position: relative;
                top: -10px;
            }
          }
          input[type=submit], button{
            background-image: linear-gradient(52.96deg, #3F7DFE 10.7%, #B183FF 92%);
            width: 100%;
            height: 50px;
            color: ${props => props.theme.colors.white};
            border-radius: 10px;
            font-size: 14px;
            border: none;
            align-self: center;
            cursor: pointer;
            margin: 40px 0 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
    }
  }
`;