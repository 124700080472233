import React, {useState, useEffect} from 'react'
import {useQuery } from 'react-query'
import { useLocation, useHistory } from "react-router-dom";
import styled from 'styled-components';
import {fetchTalents, fetchCategories} from '../../module'
import { Card, Loader } from '../../components'
import DesktopFilter from './desktopFilter'
import MobileFilter from './mobileFilter'

const ExploreContainer = styled.div`
  background-color: ${props => props.theme.colors.background};
  p.count{
    color: white;
    padding: 0 0 0 50px;
  }
  h3.no-match{
    color: ${({theme}) => theme.colors.white};
    text-align: center;
    padding: 50px 0;
  }
  .cards{
      width: 100%;
      padding: 20px 50px 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, auto));
      grid-column-gap: 20px;
      grid-row-gap: 30px;
  }
  @media only screen and (max-width: 767px){
    p.count{
      padding: 0 0 0 10px;
    }
    .cards{
      padding: 10px;
      grid-template-columns: repeat(auto-fill, minmax(140px, auto));
      grid-column-gap: 10px;
      grid-row-gap: 15px;
    }
  }
`;


const Explore = () => {
  const pageUrl = useLocation()
  const history = useHistory();
  const { isLoading: talentLoading, error: talentError, data: talentData } = useQuery('fetchTalents', fetchTalents)
  const { isLoading: categoryLoading, error: categoryError, data: categoryData } = useQuery('fetchCategories', fetchCategories)
  const [filterWith, setFilterWith] = useState<'category' | 'price' | 'rating' | 'responseTime' | 'none'>('none')
  const [filterOption, setFilterOption] = useState({
    category: 'All',
    price: 'All',
    rating: 'All',
    responseTime: 'All',
  })

  const AllFilterOptions = {
    category: [ 'All', "Influencer", "Big-Brother Stars", "Comedians", "Tv-show Stars", "MTv-Base Stars"],
    price: [ 'All', '10,000 - 20,000', '20,000 - 50,000', '50,000 - 100,000', '100,000 - 150,000', '150,000 - 200,000'],
    rating: [ 'All', '1 star & up', '2 stars & up', '3 stars & up', '4 stars & up', '5 stars & up'],
    responseTime: [ 'All', '< 1 day', '< 2 days', '< 3 days', '< 4 days', '< 5 days'],
  }

  useEffect(() => {
    ( () => {
      if(pageUrl.search){
        setFilterOption({
          ...filterOption,
          category: pageUrl.search.slice(10)
        })
        history.replace("/explore");
      }
      console.log(pageUrl.search)
    })()
  }, [])

  if(talentLoading || talentError || categoryLoading || categoryError) {
    return (<Loader />)
  }

  const filteredTalentData = talentData
    .filter((talent:any, index:any, arr:any) => {
      if(filterOption.category === 'All'){
        return arr
      }else{
        return talent.category_name === filterOption.category
      }
    })
    .filter((talent:any, index:any, arr:any) => {
      if(filterOption.price === 'All'){
        return arr
      }else{
        let filterPrice = filterOption.price.split(" - ")
        let lowerPriceRange = parseInt(filterPrice[0].replace(",", ""))
        let upperPriceRange = parseInt(filterPrice[1].replace(",", ""))
        return parseInt(talent.video_price) >= lowerPriceRange && parseInt(talent.video_price) <= upperPriceRange
      }
    })
    .filter((talent:any, index:any, arr:any) => { // response_time
      if(filterOption.responseTime === 'All'){
        return arr
      }else{
        return parseInt(talent.response_time) <= parseInt(filterOption.responseTime.slice(2,3))
      }
    })

    return (
        <ExploreContainer>
            <DesktopFilter
              filterWith={filterWith}
              setFilterWith={setFilterWith}
              filterOption={filterOption}
              setFilterOption={setFilterOption}
              AllFilterOptions={AllFilterOptions}
            />
            <MobileFilter
              filterOption={filterOption}
              setFilterOption={setFilterOption}
              AllFilterOptions={AllFilterOptions}
            />
              <p className="count">{filteredTalentData.length} {filteredTalentData.length > 1 ? 'talents' : "talent"}</p>
                {
                  filteredTalentData.length < 1 ? (
                    <h3 className="no-match">Oops! no match found</h3>
                  ) :(
                    <div className="cards">
                      {
                        filteredTalentData.map((celeb: any) => <Card
                          dynamic={true}
                          profile_pics={celeb.profile_pics}
                          chat_price={celeb.chat_price}
                          First_name={celeb.First_name}
                          Last_name={celeb.Last_name}
                          video_price={celeb.video_price}
                          category_name={celeb.category_name}
                        />)
                      }
                    </div>
                  )
                }
              {/* <div className="cards">
                {
                  filteredTalentData.length < 1 ? (
                    <h3 className="no-match">Oops! no match found</h3>
                  ) :(
                    <>
                      {
                        filteredTalentData.map((celeb: any) => <Card
                          dynamic={true}
                          profile_pics={celeb.profile_pics}
                          chat_price={celeb.chat_price}
                          First_name={celeb.First_name}
                          Last_name={celeb.Last_name}
                          video_price={celeb.video_price}
                          category_name={celeb.category_name}
                        />)
                      }
                    </>
                  )
                }
              </div> */}
        </ExploreContainer>
    )
}

export default Explore
