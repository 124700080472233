import React from 'react'
import { NavLink } from 'react-router-dom';
import styled from 'styled-components'
import Picture from '../../../assets/talentReg.png';
import Picture2 from '../../../assets/talentReg2.png';

const TalentRegContainer = styled.div`
  min-height: 600px;
  background-image: url(${Picture2});
  background-repeat: no-repeat;
  background-size: cover;
  .gradient{
    min-height: 600px;
    background-image: linear-gradient(180deg, rgba(177, 131, 255, 0) 4.17%, rgba(177, 131, 255, 0.44) 40.63%, rgba(177, 131, 255, 0.81) 65.1%, #B183FF 99.99%, #B183FF 100%);
    color: ${ ({theme}) => theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1{
        width: 70%;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 44px;
        text-align: center;
        margin: 0 0 30px;
    }
    p{
        width: 80%;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 37px;
        text-align: center;
        margin: 0 0 60px;
    }
    a{
        background-color: ${({theme}) => theme.colors.white};
        color: ${({theme}) => theme.colors.purple};
        padding: 24px 80px;
        border-radius: 10px;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
    }
  }
  @media only screen and (min-width: 768px){
    min-height: 40vw;
    background-image: url(${Picture});
    background-size: contain;
    .gradient{
      min-height: 40vw;
      background-image: linear-gradient(90deg, #B183FF 0.13%, #B183FF 30.71%, rgba(177, 131, 255, 0.78) 47.15%, rgba(196, 196, 196, 0) 113.83%);
      align-items: flex-start;
      padding: 0 0 0 50px;
      h1, p{
        text-align: start;
      }
      h1{
        width: 56%;
        font-size: calc(2.25rem + ((1vw - 7.68px) * 4.1667));
        font-style: normal;
        font-weight: 700;
        line-height: calc(1.2 * (2.25rem + ((1vw - 7.68px) * 4.1667)));
        letter-spacing: 0em;
        margin-bottom: calc(.7 * (2.25rem + ((1vw - 7.68px) * 4.1667)));
      }
      p{
        width: 50%;
        font-size: calc(0.875rem + ((1vw - 7.68px) * 1.4881));
        line-height: calc(1.2 * (0.875rem + ((1vw - 7.68px) * 1.4881)));
        margin-bottom: calc(1.5 * (2.25rem + ((1vw - 7.68px) * 4.1667)));
      }
    }
  }
  @media (min-width: 1440px) {
    .gradient{
      h1{
        font-size: 64px;
        line-height: 78px;
      }
    }
  }
`;
const TalentReg = () => {
    return (
        <TalentRegContainer>
            <div className="gradient">
                <h1>Want to become a talent on Tialala?</h1>
                <p>If you have fans and want to join Tialala as talent, apply to enroll with us and we'll be in touch.</p>
                <NavLink to="/apply">Enroll as Talent</NavLink>
            </div>
        </TalentRegContainer>
    )
}

export default TalentReg
