import React from 'react'
import {default as LoaderSpinner} from "react-loader-spinner";
import styled from 'styled-components';

const LoaderStyle = styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({theme}) => theme.colors.background};
`;

const Loader = () => {
    return (
      <LoaderStyle>
        <LoaderSpinner
        type="TailSpin"
        color="#FFF"
        height={50}
        width={50}
        timeout={99999}
        />
      </LoaderStyle>
    );
}

export default Loader