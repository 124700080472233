import { createGlobalStyle, DefaultTheme } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    list-style-type: none;
    box-sizing: border-box;
    outline: none;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
  }
  .checkout-form{
    display: flex;
    justify-content: center;
    // background-color: green;
  }
  .paystack-button{
    
    align-self: center;
    background: linear-gradient(90deg, rgba(81,126,254,1) 0%, rgba(135,129,255,1) 100%);;
    padding: 10px 40px;
    border-radius: 15px;
    border:none;
    color: white
  }
`;

declare module "styled-components" {
    export interface DefaultTheme {
      colors: {
        blue: string;
        purple: string;
        lilac: string;
        gradientStart: string;
        gradientEnd: string;
        background: string;
        deepPurple: string;
        newPurple: string;
        white: string;
        whitely: string;
        black: string;
        grey1:string;
        grey2:string;
        grey3:string;
        grey4:string;
        greyD7:string;
      };
    }
  }


export const theme: DefaultTheme = {
  colors: {
    blue: '#2F92ED',
    purple: '#6956DD',
    lilac: '#9658FF',
    gradientStart: '#3F7DFE',
    gradientEnd: '#B183FF',
    background: '#0E0123',
    deepPurple: '#230456',
    newPurple: '#150135',
    white: '#FFFFFF',
    whitely: '#FCFCFC',
    black: '#000000',
    grey1:'#707070',
    grey2:'#B0B0B0',
    grey3:'#DBDBDB',
    grey4:'#EDEDED',
    greyD7:'#D7D7D7',
  },
};
