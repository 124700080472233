import React from 'react'
import styled from 'styled-components'
import {Icons} from '../../components'
import { CopyToClipboard } from 'react-copy-to-clipboard';

const InviteStyle = styled.div`
    background-color: ${({theme}) => theme.colors.background};
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .mobile{
        display: none;
    }
    .container{
        background-color: ${props => props.theme.colors.deepPurple};
        display: flex;
        flex-direction: column;
        padding: 150px 50px;
        .text{
            h1{
                color: white;
                font-size: 40px;
                span{
                    background-color: #9658FF;
                    background-image: linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%);
                    background-image: -webkit-linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -moz-background-clip: text;
                    -webkit-text-fill-color: transparent; 
                    -moz-text-fill-color: transparent;
                }
            }
            p{
                font-size: 18px;
                color: white;
            }
        }
        .copy{
            border: 0.5px solid white;
            border-radius: 16px;
            margin: 45px 0 65px;
            height: 80px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            p{
                font-size: 16px;
                font-weight: 400;
                color: #DCC8FF;
            }
            svg{
                position: absolute;
                top: 25px;
                right: 20px;
            }
        }
        button{
            align-self: center;
            width: 280px;
            height: 64px;
            background: linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%);
            border: none;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            p{
                font-size: 24px;
                font-weight: 700;
                color: white;
                margin: 0 0 0 20px;
            }
            svg{
              margin: 5px 0 0;
            }
        }
    }
    @media(max-width: 767px) {
        .desktop{
            display: none;
        }
        .mobile{
            display: block;
        }
        .container{
            .text{
                h1{
                    font-size: 24px;
                }
                p{
                    font-size: 14px;
                }
            }
            .copy{
                margin: 40px 0 50px;
                p{
                    font-size: 12px;
                }
                svg{
                    top: 30px;
                }
            }
            button{
                width: 200px;
                height: 64px;
                p{
                    font-size: 16px;
                    margin: 0 0 0 10px;
                }
            }
        }
    }
`;

const Invite = () => {
    const inviteId = 'fy4537-vhsjkal-674bdns-myu2o'
    return (
        <InviteStyle>
            <div className="container">
                <div className="text">
                    <h1>Hello,Free <span>Tialala</span></h1>
                    <p>Get free $35 when you get your friends to register on the touch platform</p>
                </div>
                <CopyToClipboard text={inviteId} onCopy={() => console.log('copied')}>
                    <div className="copy">
                        <p>{inviteId}</p>
                        <div className="desktop">
                            <Icons icon="copy" size="30"/>
                        </div>
                        <div className="mobile">
                            <Icons icon="copy" size="20"/>
                        </div>
                    </div>
                </CopyToClipboard>
                <button onClick={() => {
                    if(navigator.share){
                        navigator.share({
                            title: 'Tialala Invite Code',
                            text: inviteId
                        })
                        .then( () => console.log('shared'))
                        .catch(err => console.log(err))

                    }else{
                        console.log('no sharing...')
                    }
                }}>
                    <div className="desktop">
                        <Icons icon="share-2" size="30"/>
                    </div>
                    <div className="mobile">
                        <Icons icon="share-2" size="20"/>
                    </div>
                    <p>Share Link</p>
                </button>
            </div>
        </InviteStyle>
    )
}

export default Invite
