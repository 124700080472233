import { useState } from 'react';
import {useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import {fetchTalents} from '../../../module'
import { Search } from '../navbarStyle';
import { Icons } from '../../../components';

export default function SearchEngine({fullMobileSearch, clickHandler}:{fullMobileSearch?: boolean, clickHandler?: any}) {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchIndex, setSearchIndex] = useState('');
  const { isLoading, error, data } = useQuery('fetchTalents', fetchTalents);

  const openSearchBox = () =>{
    setSearchOpen(true)
    clickHandler && clickHandler(true)
  }
  const closeSearchBox = () =>{
    setSearchOpen(false)
    clickHandler && clickHandler(false)
  }

  return (
        <Search fullMobileSearch={fullMobileSearch}>
        {
            searchOpen ? (
            <>
            <div className="searchBar">
                <Icons icon="search" size='20'/>
                <input type="text" value={searchIndex} name="main search" placeholder="Search" onChange={e => setSearchIndex(e.target.value)}/>
                <Icons icon="x" size='20' clickHandler={closeSearchBox}/>
            </div>
            <div className="searchResult">
                { (isLoading ||  error || !searchIndex) ? null : (
                    data
                      .filter((talentList: any) =>
                          talentList.First_name.toLowerCase().includes(searchIndex.toLowerCase()) ||
                          talentList.Last_name.toLowerCase().includes(searchIndex.toLowerCase())
                      ).map((talent: any) =>(
                          <NavLink to={`/celebrity/${talent.First_name}${talent.Last_name}`}>
                            <div className="talent" key="talent.profile_pics">
                                <img src={talent.profile_pics} alt="" />
                                <p>{`${talent.First_name} ${talent.Last_name}`}</p>
                            </div>
                          </NavLink>
                          )
                      )
                )}
            </div>
            </>
            ) : (
            <Icons icon="search" size='30' clickHandler={openSearchBox}/>
            )
        }
        </Search>
  )
}