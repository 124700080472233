import { useForm, SubmitHandler } from "react-hook-form";
import { Twitter, Youtube, Facebook, Linkedin } from 'react-bootstrap-icons';
import {TialalaLogo, Explore} from '..';
import { FooterContainer } from "./footerStyles";
import Footlist from './_footer.json'

type Inputs = {
  email: string,
};

export default function Footer() {
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = data => console.log(data);
  return (
    <FooterContainer>
      <div className="main">
        <div className="id">
        <TialalaLogo size={150} />
        </div>
        <div className="content">
          <Explore header="About" list={Footlist.one}/>
          <Explore header="Support" list={Footlist.two} rightMargin={1}/>
        </div>
        <div className="subscribe">
          <p>Be the first to know about the newest stars and best deals on Tialala</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="email" {...register("email", { required: true })} />
            {/* {errors.email && <span>This field is required</span>} */}
            <input type="submit"  value="SUBSCRIBE"/>
          </form>
          <div className="socials">
            <a href="https://facebook.com/tialala" target="_blank" rel="noopener noreferrer"> <Facebook color="white" size={24} /> </a>
            <a href="https://linkedin.com/tialala" target="_blank" rel="noopener noreferrer"> <Linkedin color="white" size={24} /> </a>
            <a href="https://twitter.com/tialala" target="_blank" rel="noopener noreferrer"> <Twitter color="white" size={24} /> </a>
            <a href="https://youtube.com/tialala" target="_blank" rel="noopener noreferrer"> <Youtube color="white" size={24} /> </a>
          </div>
        </div>
      </div>
      <div className="copyright">
        <i className="large material-icons">copyright</i>
        <h3>2021 Copyright  Touch. All rights reserved</h3>
      </div>
    </FooterContainer>
  )
}