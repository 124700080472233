import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const AuthPart = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`; 

export const NavButton = styled.div<{ isWhite?: boolean }>`
  height: 40px;
  width: 50%;
  background-color: ${({theme:{colors}, isWhite}) => (isWhite ? colors.white : 'transparent')};
  border: 1px solid white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 5px 2.5px;
  @media only screen and (min-width: 768px) {
    margin: 0px 2.5px;
    min-width: 80px;
    max-width: 120px;
    width: 10vw;
  }
  a{
    font-size: 16px;
    line-height: 18px;
    font-style: normal;
    font-weight: 500;
    color: white;
  }
  a.gradient-text{
    background-color: #9658FF;
    background-image: linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%);
    background-image: -webkit-linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
  }
`;

export default function AuthButtons() {
  return (
    <AuthPart>
      <NavButton isWhite={true}>
        <NavLink to="/signup" className="gradient-text">Sign up</NavLink>
      </NavButton>
      <NavButton>
        <NavLink to="/login">Sign in</NavLink>
      </NavButton>
    </AuthPart>
  )
}