import {baseApi} from '.'

export const updateRequest = async (payload: any) => await baseApi.post('/home/update/request', payload)

export const makeRequest = async (payload:any) => {
    const token = window.localStorage.getItem("token") || ""
    const response = await baseApi.post('/home/save/request', payload, {
    headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
        Authentication: `Bearer ${JSON.parse(token)}`
    }
    })
    return response
}