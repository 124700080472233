import styled from 'styled-components';
import {NavLink} from 'react-router-dom'
import {useQuery} from 'react-query';
import { fetchUserProfile, baseApi } from '../../../module'
import {useAuth} from '../../../context'
import {Icons} from '../..';
import userAvatar from '../../../assets/logo.png'
import { TialalaLogo } from '../..';

const ProfileContainer = styled.div`
  .profile{
    display: flex;
    flex-direction: row;
    align-items: center;
    .circle{
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid white;
        border-radius: 50px;
        img{
            border-radius: 50%;
            object-fit: cover;
        }
    }
  }
`;
const LinkContainer = styled.div`
    background-color: #150135;
    position: absolute;
    top: 80px;
    right: 0;
    padding: 20px 50px 20px 24px;
    .group{
      display: flex;
      padding: 5px 0;
      svg{
        margin: 0px 6px 0 0;
      }
      a,p{
        color: white;
        font-size: 14px;
      }
      p{
        cursor: pointer;
        color: #F47474;
      }
    }
    @media(max-width: 767px) {
      position: static;
      background-color: transparent;
      padding: 0;
      .group{
        padding: 5px 0;
        svg{
          margin: 5px 10px 0 0;
        }
        p{
          cursor: pointer;
          color: #F47474;
        }
      }
    }
`;

export const ProfileLinks = () =>{
  const {logout} = useAuth()
  return(
    <LinkContainer>
      <div className="group">
      <Icons icon='user' size="14"/>
      <NavLink to="/profile">profile</NavLink>
      </div>
      {/* <div className="group">
      <Icons icon='calendar' size="14"/>
      <NavLink to="/invite">Invite Friends</NavLink>
      </div> */}
      <div className="group" onClick={logout}>
      <Icons icon='log-out' size="14" stroke="#F47474"/>
      <p>Logout</p>
      </div>
    </LinkContainer>
  )
}

type ProfileProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Profile({isOpen, setIsOpen}:ProfileProps) {
  const {logout} = useAuth()
  const { isLoading, error, data } = useQuery('fetchUserProfile', () => fetchUserProfile(logout))

  if(isLoading || error) return null
  console.log(data)
  return (
    <ProfileContainer onClick={() => setIsOpen(!isOpen)}>
      <div className="profile">
        <div className="circle">
          <img src={data.profilePics || userAvatar} alt="user profile" width="45" height="45"/>
        </div>
        <Icons icon={isOpen ? "chevron-up" : "chevron-down"} size="25"/>
      </div>
      {
        isOpen && <ProfileLinks />
      }
    </ProfileContainer>
  )
}