import React, {useState, useRef} from 'react'
import {NavLink} from 'react-router-dom'
import styled from 'styled-components'
import { useFetchTalentDetails } from '../../../module'
import { Loader } from '../../../components';
import {Icons, BreadCrumbStyle} from '../../../components'

const ProfileContainer = styled.div<{imageUrl?: string}>`
  margin: 50px 0 30px;
  background-color: ${({theme}) => theme.colors.newPurple};
  width: 72%;
  align-self: center;
  color: ${({theme}) => theme.colors.white};
  display: flex;
  /* border: 5px solid red; */
  border-radius: 10px;
  .video, .details{
    width: 32vw;
    height: 38vw;
    border-radius: 10px;
    position: relative;
    video{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
    .muteIcon, .playIcon{
      position: absolute;
      cursor: pointer;
      height: 40px;
      width: 40px;
      border-radius: 40px;
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      svg{
        margin: 5px 0 0 2px;
      }
    }
    .muteIcon{
      top: 20px;
      right: 20px;
    }
    .playIcon{
      bottom: 35px;
      right: calc(50% - 20px);
    }
  }
  .picture{
    /* background-image: url(${props => props.imageUrl}); */
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
  }
  .detailWrap{
    /* border: 2px solid yellow; */
    width: calc(100% - 32vw);
    display: flex;
    justify-content: center;

  }
  .details{
    /* border: 2px solid purple; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    h1{
      font-size: 24px;
      font-weight: 500;
    }
    p{
      font-size: 9.6px;
      font-weight: 400;
      color: #2F92ED;

      /* line-height: 26px; */
    }
    p.about{
      color: #A7A3A3;
    }
    h3{
      font-size: 12px;
      font-weight: 400;
      margin-top: 18px;
    }
    .info{
      background-color: #1E024B;
      /* margin: 40px 0 0;
      padding: 20px 0; */
      margin: 20px 0 0;
      padding: 10px 0;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .starGroup{
        display: flex;
        align-items: center;
        i{
          margin-right: 5px;
          color: #FFC833;

        }
      }
      h4{
        font-size: 20px;
        font-weight: 500;
      }
      p{
        color: #ACAAAA;
        font-size: 12px;
      }
    }
    .buttons{
      display: flex;
      margin: 10px 0 0;
      justify-content: space-evenly;
      button{
        background-image: linear-gradient(52.96deg, #3F7DFE 10.7%, #B183FF 92%);
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({theme}) => theme.colors.white};
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 10px;
        align-self: center;
        cursor: pointer;
        h5{
          /* margin-left: 10px; */
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    @media (min-width: 768px) {
      h1{
        font-size: calc(1.5rem + ((1vw - 7.68px) * 2.381));
      }
      p{
        font-size: calc(0.4 * (1.5rem + ((1vw - 7.68px) * 2.381)));
      }
      h3{
        font-size: calc(0.5 * (1.5rem + ((1vw - 7.68px) * 2.381)));
        margin-top: calc(0.75 * (1.5rem + ((1vw - 7.68px) * 2.381)));
      }
    }
    @media (min-width: 1440px) {
      h1{
        font-size: 40px;
      }
      p{
        font-size: 24px;
      }
      h3{
        font-size: 24px;
        margin-top: 30px;
      }
    }
  }
  @media (max-width: 767px) {
    margin: 0 0 30px;
    flex-direction: column;
    width: 100%;
    .detailWrap{
      width: 100%;
    }
    .video, .details{
      width: 100%;
      height: 80vw;
      padding: 0 10px;
    }
    .video{
      height: 60vh;
    }
    
    .details{
      height: fit-content;
      padding: 15px 10px;
      h3{
        font-size: 14px;
      }
      p{
        font-size: 13px;
      }
      .buttons{
        margin: 30px 0 0;
      }
    }
  }
`;

const Profile = ({details}:{details:any}) => {
    const [videoMuted, setVideoMuted] = useState(true)
    const [play, setPlay] = useState(true)
    const { _id, profile_pics, First_name, Last_name, category_name, additional_info, video_price, video_link, thumbnail, response_time = "7" } = details
    const {response, error, loading} = useFetchTalentDetails(_id)

    const videoRef = useRef<HTMLVideoElement>(null);
    const PlayControl = (event: any) => {
      if(videoRef && videoRef.current){
        if(play === true){
          videoRef?.current?.pause();
          setPlay(false)
        } else{
          videoRef?.current?.play();
          setPlay(true)
        }

      }
    }
    if(loading || error) return <Loader />
    const reviewList = response.data.data
      .filter(( item: any) => item.rating)
    const Ratings = () => {
      const noOfReviews = reviewList.length
      if (noOfReviews === 0){
        return 0
      }
      let totalRatings = 0
      reviewList.forEach((review: any) => totalRatings += parseInt(review.rating))
      console.log((totalRatings / noOfReviews).toFixed(1))
      return (totalRatings / noOfReviews).toFixed(1)
    }
    return (
      <>
        <BreadCrumbStyle>
          <NavLink to="/">Home</NavLink>
          <i className="large material-icons">navigate_next</i>
          <NavLink to="/explore">Celebrities</NavLink>
          <i className="large material-icons">navigate_next</i>
          <NavLink to={`/celebrity/${First_name.trim()}${Last_name.trim()}`}>{First_name} {Last_name}</NavLink>
        </BreadCrumbStyle>
        <ProfileContainer imageUrl={profile_pics}>
          <div className="video">
            <div className="muteIcon"  onClick={ () => setVideoMuted(!videoMuted)}>
              <Icons icon={videoMuted ? "volume-x" : "volume-2"} size="25" />
            </div>
            <div className="playIcon"  onClick={PlayControl}>
              <Icons icon={play ? "pause" : "play"} size="25" />
            </div>
            <video ref={videoRef} autoPlay={play} playsInline={true} poster={thumbnail} loop={true} muted={videoMuted}>
              <source src={video_link || "https://tialala.s3.us-east-2.amazonaws.com/tialalavideodir/tialala.mp4"} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
            <div className="detailWrap">
              <div className="details">
                <div>
                <h1>{`${First_name} ${Last_name}`}</h1>
                <p>{category_name}</p>
                </div>
                <div>
                  <h3>About me</h3>
                  <p className="about">{additional_info}</p>
                </div>
                <div className="info">
                  <div className="infoChild">
                    <h4>{response_time} days</h4>
                    <p>Response time</p>
                  </div>
                  <div className="infoChild">
                    <div className="starGroup">
                      <i className="large material-icons">star</i>
                      <h4>{Ratings()}</h4>
                    </div>
                    <p>{`Reviews ${reviewList.length}`}</p>
                  </div>
                </div>
                <div className="buttons">
                  <button onClick={ () => window.open(`/request/${First_name.trim()}${Last_name.trim()}`)}>  <h5>{`Video request ₦${video_price}`}</h5>  </button>
                  {/* <NavLink to={`/request/${First_name.trim()}${Last_name.trim()}`}> <h5>{`Video request ₦${video_price}`}</h5> </NavLink> */}
                </div>
              </div>
            </div>
        </ProfileContainer>
      </>
    )
}

export default Profile
