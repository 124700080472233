import { useState, useEffect } from 'react';
import {useLocation} from "react-router-dom";
import {FirstPart, LastPart, LargeNav, SmallNav} from './navbarStyle';
import {useAuth} from '../../context'
import {Icons, Explore, TialalaLogo} from '..';
import SearchEngine from './navbarComponents/searchEngine'
import AuthButton from './navbarComponents/authButton';
import MobileExplore from './navbarComponents/mobileExplore';
import Profile from './navbarComponents/_profile';
import Explorefile from './_explore.json';

export default function Navbar({isTransparent, navPosition}: {isTransparent?: boolean, navPosition?: string}) {
  const {isAuth} = useAuth()
  const location = useLocation();
  const [showExplore, setShowExplore] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [fullMobileSearch, setfullMobileSearch] = useState(false);


  useEffect(() => {
    setShowExplore(false)
    setProfileOpen(false)
  },[location])
    
  return (
    <>
      <SmallNav isTransparent={isTransparent} navPosition={navPosition}>
        {
          !fullMobileSearch && (
            <>
              <Icons icon="menu" size="25" clickHandler={() => setShowExplore(true)}/>
              <TialalaLogo size={80} />
            </>
          )
        }
        <SearchEngine clickHandler={setfullMobileSearch} fullMobileSearch={fullMobileSearch}/>
        <MobileExplore display={showExplore} setDisplay={setShowExplore}/>
      </SmallNav>
      <LargeNav isTransparent={isTransparent} navPosition={navPosition}>
        <FirstPart isExplore={showExplore}>
          <TialalaLogo size={120} />
          <div className="explore">
            <h3 onClick={() => setShowExplore(!showExplore)}>Explore</h3>
            <div className="allExplores">
            <Explore header="POPULAR" list={Explorefile.one}/>
            <Explore header="CATEGORIES" list={Explorefile.two} columnCount={3}/>
            </div>
          </div>
        </FirstPart>
        <LastPart>
          <SearchEngine />
          {
            isAuth ? <Profile isOpen={profileOpen} setIsOpen={setProfileOpen}/> : <AuthButton />
          }

        </LastPart>
      </LargeNav>
    </>
  )
}