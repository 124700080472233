import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Logo from '../assets/logo.png'

export const BrandContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    h3{
      margin-left: 8px;
      color: ${({theme:{colors}}) => colors.white};
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }
`;

type BrandProps = {
  width?: number;
  height?: number;
}

export default function Brand({width, height}: BrandProps) {
  return (
    <BrandContainer>
      <NavLink to="/">
        <img src={Logo} alt="Tialala logo" width={width || 50} height={height || 50}/>
      </NavLink>
    </BrandContainer>
  )
}