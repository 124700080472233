import React, {useState, useEffect} from 'react'
import {baseApi} from '.'

export const useFetchRequestId = (request: string) => {
    const [response, setResponse] = useState<any| null>(null);
    const [error, setError] = useState<any| null>(null);
    const [loading, setloading] = useState(true);

    useEffect(() => {
		(async () => {
			try {
				const {data, status} = await baseApi(`/home/get/request/${request}`)
				setResponse({data, status})
			} catch (error) {
                setError(error)
                return
            }finally{
                setloading(false)
            }
		})();

	}, []);
    
    return {response, error, loading}
}