import React, {useState} from 'react'
import { useHistory } from "react-router-dom";
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications';
import {Icons, Spinner} from '../../../components'
import {makeRequest} from '../../../module'
import userAvatar from '../../../assets/logo2.png'
import advice from '../../../assets/occasions/advice.png'
import anniversary from '../../../assets/occasions/anniversary.png'
import appreciation from '../../../assets/occasions/appreciation.png'
import celebration from '../../../assets/occasions/celebration.png'

const SummaryStyle = styled.div`
  background: linear-gradient(112.98deg, rgba(72, 69, 193, 0.5) 0%, rgba(33, 18, 78, 0.51) 79.96%);
  box-shadow: 0px 0.847458px 20.339px -0.847458px rgba(51, 51, 51, 0.1);
  border-radius: 10px;
  padding: 25px 25px;
  color: ${({theme}) => theme.colors.white};
  .summary{
    border: 0.847458px solid #7C72A8;
    border-radius: 10px;
    padding: 20px 10px 10px 20px;
    width: 100%;
    .summaryTop{
      display: flex;
      align-items: center;
      margin: 0 0 50px;
      img{
        height: 80px;
        width: 80px;
        border-radius: 40px;
        border: 2px solid white;
        object-fit: cover;
      }
      .person{
          margin: 0 20px;
        h3{
          font-weight: 400;
          font-size: 24px;
          line-height: 40px;
        }
        p{
            font-size: 13px;
            line-height: 18px;
        }
      }
    }
    h6{
      color: #A09E9E;
      font-size: 16px;
      line-height: 11px;
      margin-bottom: 10px;
    }
    p.order-summary{
        font-size: 14px;
        font-weight: 400;
        word-break: break-all;
        width: 100%;
    }
    .others-summary{
        display: flex;
        
        margin: 50px 0 0;
        i{
          font-size: 26px;
          margin: 0 0 0 10px;
        }
        img{
          height: 25px;
          width: 25px;
          tintColor: "#FFFFFF"
        }
    }
}
    .summary-price{
        display: flex;
        margin: 40px 0 20px;
        .price-dot{
            border: thin solid #707070;
            margin: 3px 0 0;
            height: 25px;
            width: 25px;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            .price-dot-fill{
                background: #B183FF;
                height: 12px;
                width: 12px;
                border-radius: 25px;
            }
       }
        .price-detail{
            margin: 0 0 0 10px;
            p.price{
                font-weight: 500;
                font-size: 14px;
            }
            p.time{
                font-size: 15.2542px;
                line-height: 11px;
                color: #A09E9E;
                margin-top: 10px;
            }
        }
    }
    .checkbox{
      display: flex;
      align-items: center;
      margin: 0 0 80px;
      input[type=checkbox]{
        height: 25px;
        width: 25px;
      }
      p{
        font-size: 14px;
        padding: 0 0 0 5px;
        color: #2F92ED;

      }
    }
    button{
    color: white;
    background: linear-gradient(52.96deg, #3F7DFE 10.7%, #B183FF 92%);
    font-size: 14px;
    border-radius: 10px;
    border: none;
    width: 100%;
    height: 60px;
    }
`;

const Summary = ({requestData}: {requestData: any}) => {
  const [isLoading, setIsLoading] = useState(false)
  let history = useHistory();
  const { addToast } = useToasts();
  const [requestIsVisible, setRequestIsVisible] = useState(false)
  const {to, pronoun, amount, instruction, pics, response_time, talentFirstName, talentLastName, occasion } = requestData

  const submitRequest = async () => {
      setIsLoading(true)
      try {
        const {data, status} = await makeRequest({
          ...requestData,
          visible: requestIsVisible
        })
        console.log({data, status})
        if(status === 200){
          history.push(`/payment/${data.data._id}`);
          // window.location.assign(`/payment/${data.data._id}`)
        }else{
          addToast('Request not completed, Try again', { appearance: 'info' });
        }
      } catch (error) {
        addToast('Server Error, Try again', { appearance: 'info' });
      }finally{
        setIsLoading(false)
      }
  }
    return (
        <SummaryStyle>
            <div className="summary">
              <div className="summaryTop">
                  <img src={pics || userAvatar} alt="order" />
                  <div className="person">
                      <h3>{to}</h3>
                      <p>{pronoun}</p>
                  </div>
              </div>
              <h6>Instructions</h6>
              <p className="order-summary">{instruction}</p>
              <div className="others-summary">
                {
                  occasion === 'Birthday' ? 
                  <Icons icon="gift" size="25"/> :
                  occasion === 'Celebration' ?
                  <img src={celebration} alt="celebration" /> :
                  // <i className="large material-icons">Celebration</i> :
                  occasion === 'Give thanks' ?
                  <Icons icon="heart" size="25"/> :
                  occasion === 'Ask a question' ?
                  <Icons icon="help-circle" size="25"/> :
                  occasion === 'Get Advice' ?
                  <img src={advice} alt="advice" /> :
                  occasion === 'Anniversary' ?
                  <img src={anniversary} alt="anniversary" /> :
                  occasion === 'Just Because' ?
                  <img src={appreciation} alt="appreciation" /> :
                  null
                }
              </div>
            </div>     
            <div className="summary-price">
              <div className="price-dot">
                <div className="price-dot-fill"></div>
              </div>
              <div className="price-detail">
                <p className="price">{`Standard Price (₦ ${amount || 0})`}</p>
                <p className="time">{(response_time && response_time < 7) ? `${response_time} - 7` : 7} days </p>
              </div>
            </div>
            <div className="checkbox">
              <input type="checkbox" id='show' onChange={ e => setRequestIsVisible(e.target.checked)} />
              <p>{`Hide this request from  ${talentFirstName} ${talentLastName}’s profile`}</p>
            </div>
            <button onClick={submitRequest}>{isLoading ? (<Spinner />) : "Book now"}</button>
        </SummaryStyle>
    )
}

export default Summary
