import styled from "styled-components";

export const Wrap = styled.div`
  width: 95%;
  padding: 10px 20px;
  margin: 0 calc(2.5vw);
  border-radius: 10px;
  .picture{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    .pictureCenter{
        width: 100px;
        height: 100px;
        position: relative;
        img{
            width: 100px;
            height: 100px;
            border-radius: 50px;
            border: 5px solid white;
        }
        label.pictureInput{
          background-image: linear-gradient(52.96deg, #3F7DFE 10.7%, #B183FF 92%);
          padding: 0 5px;
          border-radius: 10px;
          position: absolute;
          bottom: 0;
          right: 0;
          cursor: pointer;
          i{
            color: ${({theme}) => theme.colors.white};
            font-size: 12px;
          }
        }
        input[type=file]{
          display: none;
        }
    }
  }
  form{
      display: flex;
      flex-direction: column;
        .unit{
            width: 100%;
            margin: 0 0 20px;
            label{
                color: ${props => props.theme.colors.white};
                margin: 0 0 20px;
                font-size: 14px;
                i{
                  font-size: 12px;
                }
            }
            .inner{
                border: thin solid ${props => props.theme.colors.greyD7};
                border-radius: 10px;
                padding: 0;
                margin: 5px 0 0;
                display: flex;
                align-items: center;
                input, select, textarea{
                  background-color: transparent;
                  padding: 0 10px;
                  border: none;
                  height: 50px;
                  width: 100%;
                  color: ${props => props.theme.colors.white};
                  border-radius: 10px;
                  font-size: 14px;
                }
                select{
                  option:checked{
                    background-color: ${props => props.theme.colors.newPurple} !important;
                    color: ${props => props.theme.colors.white} !important;
                  }
                  option:not(:checked){
                    background-color: ${props => props.theme.colors.white} !important;
                    color: ${props => props.theme.colors.newPurple} !important;
                  }
                }
                textarea{
                  height: 120px;
                  padding: 5px 20px 0;
                  &::-webkit-scrollbar {
                    display: none;
                  }
                }
            }
            span{
                color: red;
                font-size: 10px;
                position: relative;
                top: -10px;
            }
        }
        input[type=submit], button{
            background-image: linear-gradient(52.96deg, #3F7DFE 10.7%, #B183FF 92%);
            width: 100%;
            height: 50px;
            color: ${props => props.theme.colors.white};
            border-radius: 10px;
            border: none;
            font-size: 14px;
            font-weight: 400;
            align-self: center;
            cursor: pointer;
            margin: 20px 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
      
  }
  @media only screen and (min-width: 1024px){
    width: 100%;
    margin: 0;
    background: linear-gradient(114.98deg, rgba(72, 69, 193, 0.5) 0%, rgba(49, 40, 126, 0.39) 85.08%);
    box-shadow: 0px 0.866907px 20.8058px -0.866907px rgba(51, 51, 51, 0.1);
  }
`;
