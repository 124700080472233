import React from 'react'
import styled from 'styled-components'

const Wrap = styled.div<{wrapWidth:string}>`
  background-color: ${({theme}) => theme.colors.background};
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .formHolder{
    background-color: ${props => props.theme.colors.deepPurple};
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({wrapWidth}) => wrapWidth};
    margin: 0 120px;
    .formSpace{
        width: 100%;
        max-width: 660px;
    }
  }
`;

const FormWrap = ({children, wrapWidth}:{children: React.ReactNode, wrapWidth?: string}) => {
    return (
        <Wrap wrapWidth={wrapWidth || '100%'}>
            <div className="formHolder">
                <div className="formSpace">
                  {children}
                </div>
            </div>
        </Wrap>
    )
}

export default FormWrap
