import {baseApi} from '.'

export const fetchUserProfile = async (logout: any) => {
   const token = window.localStorage.getItem("token")
   try {
      if(token){
         const {status, data} = await baseApi('/home/get/user/info', {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
            Authentication: `Bearer ${JSON.parse(token)}`
         }
         })
         return data.data
         if(status === 400){
            logout()
         }
      }else{
         logout()
      }
   } catch (error) {
      return error
     console.log(error)
   }
}