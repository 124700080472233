import React from 'react'
import styled from 'styled-components';

const MainContainer = styled.div`
  width: 40%;
  color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p.header{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }
  .body{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0;
        .numberLabel{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 10px;
            .label{
                background: linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%);
                height: 35px;
                width: 35px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .connect1, .connect2, .connect3{
                background: linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%);
                width: 5px;
            }
            .connect1{
                height: 90px;
            }
            .connect2{
                height: 105px;
            }
            .connect3{
                background: linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%);
                height: 105px;
            }
        }
        .words{
            max-width: 300px;
            p{
              font-weight: 400;
              font-size: 14px;
              line-height: 23px;
              margin: 0 0 60px;
            }
        }
  }
  @media only screen and (max-width: 1023px){
    display: none;
    width: 100%;
    margin: 100px 0;
  }
  
`;

const HowTialalaWorks = () => {
    return (
        <MainContainer>
            <p className="header">How Tialala works</p>
            <div className="body">
                <div className="numberLabel">
                    <div className="label">
                    </div>
                    <div className="connect1"></div>
                    <div className="label">
                    </div>
                    <div className="connect2"></div>
                    <div className="label">
                    </div>
                    <div className="connect3"></div>
                    <div className="label">
                    </div>
                </div>
                <div className="words">
                        <p>Your request will be completed within 7 days</p>
                        <p>Your receipt and order updates will be sent to the email provided under Delivery Information</p>
                        <p>When your request is completed, we’ll email and text you a link to view, share, or download your Touch</p>
                        <p>If for some reason your video isn’t completed, the hold on your card will be removed within 5-7 business days</p>
                </div>
            </div>
        </MainContainer>
    )
}

export default HowTialalaWorks
