import React from 'react'
import styled from 'styled-components';
import Picture1 from '../../../assets/how-it-works1.png';
import Picture2 from '../../../assets/how-it-works2.png';
import Picture3 from '../../../assets/how-it-works3.png';
import Picture4 from '../../../assets/how-it-works4.png';

const MainContainer = styled.div`
  width: 100%;
  margin: 0 0 50px;
  background-color: ${props => props.theme.colors.deepPurple};
  color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  .cover1{
    width: fit-content;
    padding: 100px;
    position: relative;
    &:before, &:after{
      position: absolute;
      content: "";
      height: 175px;
      width: 175px;
      border: thin solid #E573E1;
    }
    &:before{
      top: 80px;
      left: 50px;
      border-right: none;
      border-bottom: none;
    }
    &:after{
      bottom: 80px;
      right: 50px;
      border-left: none;
      border-top: none;
    }
    @media only screen and (max-width: 767px) {
      padding: 100px 10px;
      &:before, &:after{
        display: none;
      }
    }
  }
  p.header{
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 60px;
  }
  .body{
    display: flex;
    justify-content: center;
    .text{
        display: flex;
        /* display: none; */
        margin-right: 50px;
        .numberLabel{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 25px;
            .label{
                background: linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%);
                height: 60px;
                width: 60px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                  font-size: 30px;
                  font-weight: 700;
                }
            }
            .connect1, .connect2{
                background: linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%);
                width: 5px;
                height: 90px;
            }
        }
        .words{
            margin-bottom: 50px;
            h3{
              width: 250px;
              font-weight: 700;
              font-size: 18px;
              line-height: 18px;
              margin-bottom: 10px;
            }
            p{
              font-weight: 400;
              font-size: 14px;
              line-height: 23px;
              width: 250px;
            }
        }
    }
    .pics{
        width: 100%;
        max-width: 350px;
        img{
          width: 150px;
          margin-bottom: 30px;
          &:nth-child(2n - 1){
            margin-right: 20px;
          }
          &:nth-child(2n){
            position: relative;
            top: 25px;
          }
        }
    }
    @media only screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        .text{
            margin-right: 0;
            margin-bottom: 50px;
        }
    }
  }
  
`;

const HowItWorks = () => {
    return (
        <MainContainer>
          <div className="cover1">
            <p className="header">How it works</p>
            <div className="body">
                <div className="text">
                    <div className="numberLabel">
                        <div className="label">
                            <p>1</p>
                        </div>
                        <div className="connect1"></div>
                        <div className="label">
                            <p>2</p>
                        </div>
                        <div className="connect2"></div>
                        <div className="label">
                            <p>3</p>
                        </div>
                    </div>
                    <div>
                      <div className="words">
                          <h3>Pick your celebrity</h3>
                          <p>Search on the Tialala website or mobile app for the celebrity you want to receive a video message from.</p>
                      </div>
                      <div className="words">
                          <h3>Build your personalized message</h3>
                          <p>Fill out a simple form detailing your request.</p>
                      </div>
                      <div className="words">
                          <h3>Download your video</h3>
                          <p>Once your video is ready, you’ll be notified to watch and download your personalized video. You’ll now be able to share and gift it to your special someone (or even yourself)!</p>
                      </div>
                    </div>
                </div>
                <div className="pics">
                    <img src={Picture1} alt="design pics" />
                    <img src={Picture2} alt="design pics" />
                    <img src={Picture3} alt="design pics" />
                    <img src={Picture4} alt="design pics" />
                </div>
            </div>
          </div>
        </MainContainer>
    )
}

export default HowItWorks
