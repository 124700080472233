import React from 'react'
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Carousel from "react-multi-carousel";
import { motion } from "framer-motion";
import "react-multi-carousel/lib/styles.css";
import Responsive from './cardResponsiveness.json';

const CardContainer = styled(motion.div)<{imageUrl: string, dynamic?: boolean}>`
  width: ${({dynamic}) => dynamic ? '100%' : '170px'};
  /* aspect-ratio: 170 / 216; */
  height: 216px;
  min-width: 159px;
  border-radius: 5px;
  margin: ${({dynamic}) => dynamic ? '0' : '30px 15px 0 0'};
  background: linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%);
  padding: 1px;
  display: flex;
  cursor: pointer;
  a{
      display: flex;
      width: 100%;
      border-radius: 5px;
      background-image: url(${props => props.imageUrl});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
  }
  .info{
      background-color: rgba(18, 18, 18, 0.49);
      padding: 10px;
      width: 100%;
      border-radius: 0 0 5px 5px;
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: ${props => props.theme.colors.white};
      .more{
          display: none;
          margin-bottom: 10px;
          .grp{
              display: flex;
              align-items: center;
              i{
                  margin-right: 5px;
              }
              p{
                font-weight: 400;
                font-size: 13px;
              }
              p.review{
                margin-left: 10px;
                font-weight: 400;
                font-size: 10px;
              }
              &:first-of-type{
                  i{
                    color: red;
                  }
              }
              &:nth-of-type(2){
                i{
                    color: yellow;
                }
              }
          }
      }
      .intro{
          h3{
              font-weight: 700;
              font-size: 16px;
              line-height: 16px;
              margin-bottom: 2px;
          }
          p.out{
            font-weight: 400;
            font-size: 13px;
            line-height: 11px;
          }

      }
      .price{
        margin: 10px 0 0;
        .group{
            display: flex;
            p{
                margin-left: 8px;
                font-weight: 700;
                font-size: 14px;
            }
        }
      }
  }
  @media only screen and (min-width: 768px) {
    width: ${({dynamic}) => dynamic ? '100%' : '215px'};
    /* aspect-ratio: 215 / 275; */
    height: 275px;
    margin: ${({dynamic}) => dynamic ? '0' : '15px'};
      .info{
        .intro{
          margin: 0 0 10px;
          h3{
              font-weight: 700;
              font-size: 18px;
              line-height: 18px;
              margin-bottom: 5px;
          }
          p.out{
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
          }
        }
        .price{
          .group{
              margin: 5px 0 0;
              display: flex;
              align-items: center;
              p{
                  margin-left: 15px;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 20px;
              }
          }
        }
      }
  }
`;

type CardProps = {
    profile_pics: string;
    First_name: string;
    Last_name: string;
    category_name: string;
    chat_price: string;
    video_price: string;
    dynamic?: boolean;
}

export const Card = ({
    profile_pics,
    First_name,
    Last_name,
    category_name,
    // video_price,
    dynamic
}: CardProps) => {
    return (
        <CardContainer
          dynamic={dynamic || false}
          imageUrl={profile_pics}
          whileHover={{
            scale: 1.1,
            transition: { duration: .7 },
          }}
        >
            <NavLink to={`/celebrity/${First_name.trim()}${Last_name.trim()}`}>
                <div className="info">
                    <div className="intro">
                        <h3>{`${First_name} ${Last_name}`}</h3>
                        <p className="out">{category_name}</p>
                    </div>
                    {/* <div className="price">
                        <div className="group">
                            <Icons icon="video" size="14"/>
                            <p>{`₦${video_price}`}</p>
                        </div>
                    </div> */}
                </div>
            </NavLink>
        </CardContainer>
    )
}

type GroupOfCardsProps = {
    title: string;
    link: string;
    cardInfo: CardProps[];
}

const DesktopGroupOfCards = styled(motion.div)`
  padding: 0 50px 20px;
  cursor: pointer;
  a{
    background-color: transparent;
    text-decoration: none;
    outline: none;
    cursor: default;
    &:hover, &:visited, &:active, &:link, &::selection, &::-moz-selection{
      background-color: transparent;
      text-decoration: none;
      outline: none;
      cursor: default;
    }
  }
  /* &:hover{
      .head{
        .others{
          p{
            display: block;
          }
        }
      }
  } */
  .head{
      color: ${props => props.theme.colors.white};
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3{
          font-size: 22px;
          font-weight: 700;
          line-height: 22px;
      }
      .others{
        display: flex;
        align-items: center;
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            margin: 0 0 0 15px;
        }
      }
    &:hover{
      .others{
        p, i{
          color: ${props => props.theme.colors.purple};
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const MobileGroupOfCards = styled.div`
  padding: 0 25px 30px;
  a{
    background-color: transparent;
    text-decoration: none;
    outline: none;
    cursor: default;
    &:hover, &:visited, &:active, &:link, &::selection, &::-moz-selection{
      background-color: transparent;
      text-decoration: none;
      outline: none;
      cursor: default;
    }
  }
  .head{
      color: ${props => props.theme.colors.white};
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3{
          font-size: 18px;
          font-weight: 700;
          line-height: 18px;
      }
      .both{
        display: flex;
        justify-content: center;
        align-items: center;
          p{
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              margin: 0 0 0 15px;
          }
          i{
            font-size: 14px;
          }
      }
  }
  .cards{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const GroupOfCards = ({title, link, cardInfo}: GroupOfCardsProps) => {
    
    const cardsByCategory = cardInfo.filter(talent => talent.category_name === title).slice(0, 6)

    return (
        <>
        <DesktopGroupOfCards>
            <NavLink to={`/explore?category=${link}`}>
              <div className="head">
                  <h3>{title}</h3>
                  <div className="others">
                    <p>See All</p>
                    <i className="large material-icons">navigate_next</i>
                  </div>
              </div>
            </NavLink>
          <div className="cards">
            <Carousel
            itemClass="padding-right"
              partialVisible={true}
              responsive={Responsive}
            >
                {
                  cardsByCategory.map(card => <Card 
                    profile_pics={card.profile_pics}
                    chat_price={card.chat_price}
                    First_name={card.First_name}
                    Last_name={card.Last_name}
                    video_price={card.video_price}
                    category_name={card.category_name}
                />)
                }
            </Carousel>
          </div>
        </DesktopGroupOfCards>
        <MobileGroupOfCards>
          <NavLink to={`/explore?category=${link}`}>
            <div className="head">
                <h3>{title}</h3>
                <div className="both">
                    <p>See All</p>
                    <i className="large material-icons">navigate_next</i>
                </div>
            </div>
          </NavLink>
          <div className="cards">
                {
                  cardsByCategory.map(card => <Card 
                    profile_pics={card.profile_pics}
                    chat_price={card.chat_price}
                    First_name={card.First_name}
                    Last_name={card.Last_name}
                    video_price={card.video_price}
                    category_name={card.category_name}
                />)
                }
          </div>
        </MobileGroupOfCards>
        </>
    )
}
