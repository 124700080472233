import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NavLink } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Form } from "./AuthStyles";
import AuthWrap from "./AuthForm";
import { Icons, Spinner } from "../../components";
import { useAuth } from "../../context";
import { baseApi } from "../../module";

type Inputs = {
  username: string;
  password: string;
};

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup
    .string()
    .required()
    .min(8)
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
    ),
});

const Login = () => {
  const { login } = useAuth();
  const { addToast } = useToasts();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    setIsLoading(true);
    try {
      const { data, status } = await baseApi.post("/auth/login", formData);
      if (status === 201) {
        window.localStorage.setItem("token", JSON.stringify(data.data.token));
        login();
      }
      if (status === 401) {
        addToast("incorrect password", { appearance: "info" });
      }
      if (status === 400) {
        if (
          data.error === "Exactly one of topic, token or condition is required"
        ) {
          addToast("unknown error", { appearance: "info" });
        } else {
          addToast("incorrect username", { appearance: "info" });
        }
      }
    } catch (error) {
      console.log("error message", error);
    } finally {
      setIsLoading(false);
    }
  };
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <AuthWrap
      header="Welcome"
      subHeader="Please login to continue using our app"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="unit">
          <label>Username</label>
          <div className="inner">
            <input
              type="text"
              placeholder="Username"
              {...register("username", { required: true })}
            />
          </div>
          {errors.username && <span>invalid format</span>}
        </div>
        <div className="unit">
          <label>Password</label>
          <div className="inner innerWithPadding">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              {...register("password", { required: true })}
            />
            <Icons
              icon={showPassword ? "eye-off" : "eye"}
              clickHandler={toggleShowPassword}
              size="20"
            />
          </div>
          {errors.password && <span>invalid format</span>}
        </div>
        <NavLink to="/resetpassword" className="side">
          Forgot password?
        </NavLink>
        {!isLoading ? (
          <input type="submit" value="Login" />
        ) : (
          <button>
            <Spinner />
          </button>
        )}
        <div className="clickabout">
          <p>Don't have an account?</p>
          <NavLink to="/signup" className="side">
            signup
          </NavLink>
        </div>
      </Form>
    </AuthWrap>
  );
};

export default Login;
