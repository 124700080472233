import React from 'react'
import styled from "styled-components";

type AuthWrapProps = {
    header: string;
    subHeader: string;
    children: React.ReactNode
}


const AuthWrapStyle = styled.div`
  background-color: ${({theme}) => theme.colors.background};
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .formSpace{
    background-color: ${props => props.theme.colors.newPurple};
    display: flex;
    padding: 30px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 500px;
    border-radius: 10px;
    .header{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: ${({theme}) => theme.colors.white};
      h1{
        font-size: 28px;
        font-weight: 700;
        text-align: center;
      }
      p{
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
      }
    }
  }
`;

const AuthWrap = ({header, subHeader, children}:AuthWrapProps) =>{
  return(
    <AuthWrapStyle>
      <div className="formSpace">
        <div className="header">
          <h1>{header}</h1>
          <p>{subHeader}</p>
        </div>
        {children}
      </div>
    </AuthWrapStyle>
  )
}

export default AuthWrap
