import React, {useState} from 'react';
import styled from 'styled-components';
import { useFetchTalentDetails } from '../../../module'
import { Loader } from '../../../components';
const VideoContainer = styled.div`
  display: grid;
  padding: 0 50px 50px;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 250px), 1fr));
  grid-gap: 24px;
  img{
    width: 100%;
    max-width: 290px;
    height: 310px;
    background-size: cover;
    object-fit: cover;
    border-radius: 10px;
  }
  @media (max-width: 767px){
    margin: 0 10px;
    padding: 0 10px 50px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    img{
      height: 200px;
    }
  }
`;

const VideoModal = styled.div`
  z-index: 100;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  video{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
`;

const Videos = ({celebId}:{celebId: string}) => {
   const {response, error, loading} = useFetchTalentDetails(celebId)
   const [play, setPlay] = useState("")
   if(loading || error) return <Loader />
    return (
        <VideoContainer>
            {
              response.data.data.map((video: any) => (
                <img src={video.thumbnail}
                  alt="video poster"
                  onClick={ () => setPlay(video.videoLink)}/>
              ))
            }
            {
              play && (
                <VideoModal onClick={() => setPlay("")}>
                  <video autoPlay loop muted={false}>
                    <source src={play} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </VideoModal>
              )
            }
        </VideoContainer>
    )
}

export default Videos
