import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from '../../components'

const FilterContainer = styled.div`
  color: ${props => props.theme.colors.white};
  padding: 20px 0;
  .filterAll{
    width: fit-content;
    font-size: 24px;
    border: 2px solid white;
    padding: 10px 20px;
    margin: 0 0 0 20px;
    border-radius: 50px;
    p{
      font-size: 14px;
    }
  }
  .fullPageFilter{
    min-height: 100vh;
    width: 100vw;
    background-color: #150135;
    padding: 50px 20px 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    p.hideFilter{
      position: absolute;
      top: 30px;
      right: 30px;
      font-size: 14px;
      border: 2px solid white;
      padding: 10px 20px;
      margin: 0 0 0 20px;
      border-radius: 50px;
    }
    .filter{
      color: ${props => props.theme.colors.white};
      margin: 30px 0 0;
      display: flex;
      flex-direction: column;
      p{
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          margin: 0 0 10px;
      }
      select{
          background-color: transparent;
          color: ${props => props.theme.colors.white};
          font-size: 14px;
          font-weight: 400;
          width: 100%;
          height: 50px;
          padding: 0 10px;
      }
      &:first-of-type{
        margin: 60px 0 0 ;
      }
    }
  }
  @media only screen and (min-width: 768px){
    display: none;
  } 
`;


type FilterProps ={
    filterOption: {category: string; price: string; rating: string; responseTime: string;};
    setFilterOption: React.Dispatch<React.SetStateAction<{
      category: string;
      price: string;
      rating: string;
      responseTime: string;
    }>>;
    AllFilterOptions: {
      category: string[];
      price: string[];
      rating: string[];
      responseTime: string[];
    }
}

const Filter = ({filterOption, setFilterOption, AllFilterOptions}: FilterProps) => {
  const [showFilter, setShowFilter] = useState(false)
    return (
        <FilterContainer>
          {!showFilter ? (
            <div className="filterAll" onClick={() => setShowFilter(true)}>
              <p>Show Filters</p>
            </div>
          ) : (
          <>
            <div className="fullPageFilter">
              <p className="hideFilter" onClick={() => setShowFilter(false)}>Hide Filters</p>
              {/* <i className="large material-icons" onClick={() => setShowFilter(false)}>highlight_off</i> */}
              <div className="filter">
                <p>Category</p>
                <select value={filterOption.category} onChange={e => setFilterOption({
                  ...filterOption,
                  category: e.target.value
                })}>
                  {
                    AllFilterOptions.category.map(filterValue => (
                      <option value={filterValue} key={filterValue}>{filterValue}</option>
                    ))
                  }
                </select>
              </div>
              <div className="filter">
                <p>Price</p>
                <select value={filterOption.price} onChange={e => setFilterOption({
                  ...filterOption,
                  price: e.target.value
                })}>
                  {
                    AllFilterOptions.price.map(filterValue => (
                      <option value={filterValue} key={filterValue}>{filterValue}</option>
                    ))
                  }
                </select>
              </div>
              <div className="filter">
                <p>Rating</p>
                <select value={filterOption.rating} onChange={e => setFilterOption({
                  ...filterOption,
                  rating: e.target.value
                })}>
                  {
                    AllFilterOptions.rating.map(filterValue => (
                      <option value={filterValue} key={filterValue}>{filterValue}</option>
                    ))
                  }
                </select>
              </div>
              <div className="filter">
                <p>Response Time</p>
                <select value={filterOption.responseTime} onChange={e => setFilterOption({
                  ...filterOption,
                  responseTime: e.target.value
                })}>
                  {
                    AllFilterOptions.responseTime.map(filterValue => (
                      <option value={filterValue} key={filterValue}>{filterValue}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </>
          )}
        </FilterContainer>
    )
}

export default Filter
