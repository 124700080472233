import styled from 'styled-components';
import {useQuery } from 'react-query'
import {useAuth} from '../../../context'
import {fetchCategories} from '../../../module'
import {Icons, Explore} from '../..';
import AuthButton from './authButton';
import {ProfileLinks} from './_profile'

const ExploreContainer = styled.div<{ show: boolean }>`
  position: absolute;
  display: ${({show}) => show ? "block" : "none"};
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 100;
  padding: 25px;
  background: linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%);
  div.section{
    border-top: 1px solid white;
    margin-top: 10px;
    padding-top: 15px;
  }
`;

type ExploreProps = {
    display: boolean;
    setDisplay: any;
  }


const MobileExplore = ({display, setDisplay}: ExploreProps) => {
  const { isLoading, error, data: categories } = useQuery('fetchCategories', fetchCategories)
  const {isAuth} = useAuth()
  if(isLoading || error) return null

  return (
    <ExploreContainer show={display}>
      <Icons icon="x" size="25" clickHandler={() => setDisplay(false)}/>
      <div className="section">
        <Explore header="POPULAR" list={categories}/>
      </div>
      <div className="section">
        {
          isAuth ? <ProfileLinks /> : <AuthButton />
        }
        
      </div>
    </ExploreContainer>
  )
}

export default MobileExplore