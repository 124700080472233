import React from 'react'
import { useForm, SubmitHandler } from "react-hook-form";
import {NavLink} from 'react-router-dom'
import S3 from 'react-aws-s3';
import styled from 'styled-components';

type formInputs = {
    [key: string]: string;
};


export type inputProps = {
    variant?: "input" | "textarea" | "select";
    type?: 'text' | 'tel' | 'email' | 'date' | 'password';
    placeHolder?: string;
    label: string;
    value: string;
    required?: boolean;
    selectOption?: string[];
}[];
type formProps = {
    profileImageUrl?: string; //
    submitText?: string;
    details: inputProps;
    isTransparent?: boolean;
};

export const FormContainer = styled.div<{isTransparent?:boolean}>`
  width: 100%;
  padding: 80px 50px;
  /* background-color: ${props => props.theme.colors.deepPurple}; */
  background: ${({isTransparent}) => isTransparent ? 'none' : 'linear-gradient(114.98deg, rgba(72, 69, 193, 0.5) 0%, rgba(49, 40, 126, 0.39) 85.08%)'};
  box-shadow: 0px 0.866907px 20.8058px -0.866907px rgba(51, 51, 51, 0.1);
  .picture{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
    .pictureCenter{
        width: 100px;
        height: 100px;
        position: relative;
        img{
            width: 100px;
            height: 100px;
            border-radius: 50px;
            border: 5px solid white;
        }
        label.pictureInput{
          background-image: linear-gradient(52.96deg, #3F7DFE 10.7%, #B183FF 92%);
          padding: 0 5px;
          border-radius: 10px;
          position: absolute;
          bottom: 0;
          right: 0;
          cursor: pointer;
          i{
            color: ${({theme}) => theme.colors.white};
            font-size: 12px;
          }
        }
        input[type=file]{
          display: none;
        }
    }
  }
  form{
      display: flex;
      flex-direction: column;
      label{
        color: ${props => props.theme.colors.white};
        margin: 5% 0 10px;
        font-size: calc(0.5625rem + ((1vw - 3px) * 3));
        @media (min-width: 600px){
          font-size: 18px;
          margin: 60px 0 0;
        }
      }
      input, select, textarea{
        background-color: transparent;
        color: ${props => props.theme.colors.white};
        border: thin solid #96909E;
        border-radius: 15px;
        /* margin: 0 0 5%; */
        font-size: calc(0.5625rem + ((1vw - 3px) * 3));
        @media (min-width: 600px){
          font-size: 18px;
          /* margin: 0 0 60px; */
        }
      }
      input, select, button{
        height: 10vw;
        max-height: 60px;
        padding: 0 20px;
      }
      textarea{
          padding: 10px 20px 0;
      }
      input[type=submit], button{
        background-image: linear-gradient(52.96deg, #3F7DFE 10.7%, #B183FF 92%);
        width: 80%;
        border-radius: 10px;
        align-self: center;
        cursor: pointer;
        margin-top: 10%;
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
      span{
        color: red;
        font-size: 10px;
      }
  }
`;

const Form = ({profileImageUrl, submitText, details, isTransparent}: formProps) => {
  const { register, handleSubmit, formState: { errors } } = useForm<formInputs>();
  const onSubmit: SubmitHandler<formInputs> = data => {
    console.log(data)
  };

  const ReactS3Client = new S3({
    bucketName: 'tialala',
    dirName: 'test',
    region: 'us-east-2',
    accessKeyId: 'AKIAYJRVAC6BV4ZKRZEA',
    secretAccessKey: '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',
  });

  const uploadPicture = (e:any) => {
    ReactS3Client
        .uploadFile(e.target.files[0], 'newFileName')
        .then((data: any) => console.log({data})) //data.location
        .catch((err: any) => console.error({err}))
  }

    return (
        <FormContainer isTransparent={isTransparent || false}>
                {
                    profileImageUrl && (
                        <div className="picture">
                            <div className="pictureCenter">
                                <img src={profileImageUrl} alt="" />
                                <label className="pictureInput" htmlFor="pix">
                                <i className="material-icons">photo_camera</i>
                                </label>
                                <input type="file" id="pix" onChange={uploadPicture}/>
                            </div>
                        </div>
                    )
                }
            <form onSubmit={handleSubmit(onSubmit)}>
                {
                    details.map(formUnit => {
                        const { variant = 'input', type = 'text', label, value, required = true, selectOption} = formUnit;
                        return (
                            <>
                                <label>{formUnit.label}</label>
                                {
                                    variant === "input" ? (
                                        <input key={value} type={type} placeholder={formUnit.placeHolder} {...register(value)}/>
                                    ) : variant === "textarea" ? (
                                        <textarea key={value} cols={30} rows={6} placeholder={formUnit.placeHolder}></textarea>
                                    ) : variant === "select" ? (
                                        <select key={value} {...register(formUnit.value)}>
                                            {
                                                selectOption && selectOption.map(option => (
                                                    <option value={option}>{option}</option>
                                                ))
                                            }
                                        </select>
                                    ) : null
                                }
                            </>
                        )
                    })
                }
                <input type="submit" value={submitText || "Submit"} />
            </form>
            <div className="authNavigation">
                <p>Already have an account?  <NavLink to='/login'>Login</NavLink> </p>
            </div>
        </FormContainer>
    )
}

export default Form

            // <label>First Name</label>
            // <input type="text" name="" id="" />
            // <input type="tel" name="" id="" />
            // <input type="email" name="" id="" />
            // <input type="date" name="" id="" />
            // <textarea name="" id="" cols={30} rows={5}></textarea>