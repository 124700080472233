import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {Spinner} from '../../components'
import {verifyPayment, updateRequest} from '../../module'

import { v4 as uuidv4 } from 'uuid';

type formInputs = {
[   key: string]: string;
};

const Success = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    height: 189px;
    width: 296px;
    border-radius: 10px;
    position: relative;
    .circle{
      height: 18px;
      width: 18px;
      border-radius: 40px;
      position: absolute;
      border: 3px solid white;
    }
    .one{
      top: 10px;
      left: 74px;
      background-color: #5B90FE;
    }
    .two{
      top: 35px;
      left: 147px;
      background-color: #9658FF;
    }
    .three{
      height: 6px;
      width: 6px;
      top: 11px;
      left: 217px;
      border: none;
      background-color: #9658FF;
    }
    .four{
      top: -9px;
      left: 190px;
      background-color: #5B90FE;
    }
    .five{
      height: 64px;
      width: 64px;
      border: 8px solid white;
      top: -32px;
      left: 116px;
      background: linear-gradient(52.96deg, #3F7DFE 10.7%, #B183FF 92%);
    }
    h3, p{
      text-align: center;
    }
    h3{
      color: #15051E;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      margin: 0 0 10px;
    }
    p{
      color: #263238;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  }

`;

const schema = yup.object().shape({
  otp: yup.string().required().matches(/^(\d){5}$/),
});

const OTP = ({flw_ref, requestId}: {flw_ref: string, requestId: string}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [successMsg, setSuccessMsg] = useState(false)
  const [otpError, setOtpError] = useState(false);
  const [transError, setTransError] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm<formInputs>({
    resolver: yupResolver(schema)
  });
  useEffect(() => {
    processPayment();
  }, [])
  

  const processPayment = async () => {
    setIsLoading(true)
    try {
        const tx_ref = uuidv4();
        const update = await updateRequest({tx_ref, flw_ref, requestId})
        console.log(update.status === 200) // successfully
        console.log(update)
        setSuccessMsg(true) 
      // http://localhost:8000/api/users/home/save/request
    } catch (error) {
      console.log(error)
    } finally{
      setIsLoading(false)
    }
  };

    return (
      <>
        <form>
       
          {
            !isLoading ? (<input type="submit" value="Submit OTP" />) : (
              <button>
                <Spinner />
              </button>
            )
          }
        </form>
        {
          successMsg && (
            <Success>
              <div>
                <div className="circle one"></div>
                <div className="circle two"></div>
                <div className="circle three"></div>
                <div className="circle four"></div>
                <div className="circle five"></div>
                <h3> </h3>
                <h3>Payment Successful</h3>
                <p>Congratulations you have successfully made payment</p>
              </div>
            </Success>
          )
        }
      </>
    )
}

export default OTP
