import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm, SubmitHandler } from "react-hook-form";
import { inputProps, Spinner } from '../../components'
import applyDesktop from '../../assets/applyDesktop.png';
import applyMobile from '../../assets/applyMobile.png';
import { Header, WrapStyle } from './applyStyle'
import { baseApi } from '../../module'

type formInputs = {
  [key: string]: string;
};

const ApplyContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.background};
  .pictureHeader{
    height: calc(100vw * 391 / 1440);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(${applyDesktop});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    h1{
      font-size: 48px;
      line-height: 60px;
      font-weight: 700;
      text-align: center;
      width: 80%;
    }
    p{
      margin: 14px 0 0;
      font-size: 20px;
      line-height: 25px;
      font-weight: 400;
      text-align: center;
      width: 80%;
    }
  }
  .talentForm{
    padding: 50px 0;
    width: 66%;
    align-self: center;
  }
  @media (max-width: 1024px){
    .pictureHeader{
    height: calc(100vw * 291 / 375);
    width: 100%;
    background-image: url(${applyMobile});
    h1{
      font-size: 24px;
      line-height: 30px;
    }
    p{
      margin: 14px 0 0;
      font-size: 14px;
      line-height: 20px;
    }
  }
  }
`;

const formDetails: inputProps = [
  {
    label: 'Name',
    value: 'name',
    placeHolder: 'John',
  },
  {
    label: 'Email',
    type: 'email',
    value: 'name',
    placeHolder: 'example@tialala.com'
  },
  {
    label: 'Phone number',
    type: 'tel',
    value: 'number',
    placeHolder: '234-707 -123- 45678'
  },
  {
    variant: "select",
    label: 'Where can we find you?',
    value: 'socials',
    selectOption: ['Facebook', 'Twitter', 'Instagram'],
  },
  {
    label: 'What your handle',
    value: 'socialHandle',
    placeHolder: 'facebook.com/tialala'
  },
  {
    variant: "textarea",
    label: 'Address',
    value: 'address',
    placeHolder: '123, address street.'
  },
]
const Apply = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm<formInputs>();
  const onSubmit: SubmitHandler<formInputs> = async (formData) => {
    // setIsLoading(true)
    console.log(formData)
    try {
      const {data, status} = await baseApi.post('/auth/login', formData);
    } catch (error) {
      
    }
  };
    return (
        <>
          <Header>
            <h1>Apply to join Tialala as talent!</h1>
            {/* <p>If you have fanbase and want to join Tialala as talent, you can apply here and we'll be in touch within 72 hours.</p> */}
            <p>If you have fanbase and want to join Tialala as talent, kindly send an email to the admin and we'll be in touch within 72 hours.</p>
          </Header>
          {/* <WrapStyle>
          <div className="formSpace">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="unit">
                <label>Name</label>
                <div className="inner">
                  <input type='text' placeholder='dave' {...register('name', { required: true })}/>
                </div>
                {errors.name && <span>required</span>}
              </div>
              <div className="unit">
                <label>Email</label>
                <div className="inner">
                  <input type="email" placeholder="example@tialala.com" {...register('email', { required: true })}/>
                </div>
                {errors.email && <span>required</span>}
              </div>
              <div className="unit">
                <label>Phone number</label>
                <div className="inner">
                  <input type="tel" placeholder="08081234567" maxLength={11} {...register('number', { required: true })}/>
                </div>
                {errors.number && <span>required</span>}
              </div>
              <div className="unit">
                <label>Where can we find you?</label>
                <div className="inner">
                  <select {...register("socials", { required: true })}>
                    <option value="">Choose a platform</option>
                    <option value="facebook">Facebook</option>
                    <option value="twitter">Twitter</option>
                    <option value="instagram">Instagram</option>
                  </select>
                </div>
                {errors.occasion && <span>required</span>}
              </div>
              <div className="unit">
                <label>What your handle</label>
                <div className="inner">
                    <input type="text" placeholder="facebook.com/tialala" {...register('socialHandle', { required: true })}/>
                </div>
                {errors.socialHandle && <span>required</span>}
              </div>
              <div className="unit">
                <label>Address</label>
                <div className="inner">
                  <textarea cols={30} rows={6} placeholder="123, address street." {...register('address', { required: true })}></textarea>
                </div>
                {errors.address && <span>required</span>}
              </div>
              {
                !isLoading ? (<input type="submit" value="Submit" />) : (
                  <button>
                    <Spinner />
                  </button>
                )
              }
                
            </form>
          </div>
          </WrapStyle> */}
        </>
    )
}

export default Apply
