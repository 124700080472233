import React from 'react'
import styled from 'styled-components'

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 0;
  margin: 0 0 40px;
  @media only screen and (max-width: 767px){
    display: none;
  } 
  h1{
    font-size: 28px;
    font-weight: 700;
    color: white;
    span{
      color: #E573E1;
    }
  }
  .choices{
      border-bottom: thin solidrgba(173, 171, 176, 0.34);
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 60px 0 0;
    p{
      color: #C4C4C4;
      margin: 0 75px;
      cursor: pointer;
      i{
        position: relative;
        top: 8px;
      }
    }
    .t{
      color: white;
    }
  }
  .options{
    width: 100%;
    height: 80px;
    background-color: rgba(173, 171, 176, 0.34);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
    p{
      margin: 0 20px;
      font-size: 14px;
      color: white;
      padding: 8px 25px;
      border-radius: 100px;
      cursor: pointer;
      background-color: rgba(220, 200, 255, 0.19);
    }
    p.active{
      background-color: white;
      color: #B183FF;
    }
  }
`;


type FilterProps ={
    filterWith: 'category' | 'price' | 'rating' | 'responseTime' | 'none';
    setFilterWith: React.Dispatch<React.SetStateAction<"category" | "price" | "rating" | "responseTime" | 'none'>>;
    filterOption: {category: string; price: string; rating: string; responseTime: string;}
    setFilterOption: React.Dispatch<React.SetStateAction<{
      category: string;
      price: string;
      rating: string;
      responseTime: string;
    }>>
    AllFilterOptions: {
      category: string[];
      price: string[];
      rating: string[];
      responseTime: string[];
  }
}

const Filter = ({filterWith, setFilterWith, filterOption, setFilterOption, AllFilterOptions}: FilterProps) => {

    const filterWithHandler = (filterOptionToSet: "category" | "price" | "rating" | "responseTime") => {
        if(filterWith === filterOptionToSet){
         return setFilterWith('none')
        }
        setFilterWith(filterOptionToSet)
    }
    return (
        <FilterContainer>
            <h1> <span>Explore</span> thousands of <span>Celebrities</span>  from around the world.</h1>
            <div className="choices">
                <p onClick={() => filterWithHandler('category')}>Category <i className="large material-icons">{filterWith === 'category' ? 'expand_less' : 'expand_more'}</i></p>
                <p onClick={() => filterWithHandler('price')}>Pricing <i className="large material-icons">{filterWith === 'price' ? 'expand_less' : 'expand_more'}</i> </p>
                <p onClick={() => filterWithHandler('rating')}>Average review ratings <i className="large material-icons">{filterWith === 'rating' ? 'expand_less' : 'expand_more'}</i></p>
                <p onClick={() => filterWithHandler('responseTime')}>Response time <i className="large material-icons">{filterWith === 'responseTime' ? 'expand_less' : 'expand_more'}</i></p>
            </div>
            {
              filterWith === 'none' ? null : (
                <div className="options">
                    {
                    AllFilterOptions[filterWith].map(option => (
                        <p
                        className={filterOption[filterWith] === option ? 'active' : ''}
                        key={option}
                        onClick={() => setFilterOption({
                            ...filterOption,
                            [filterWith]: option,
                        })}
                        >
                        {option}
                        </p>
                    ))
                    }
                </div>
              )
            }
        </FilterContainer>
    )
}

export default Filter
