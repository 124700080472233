import React, { ReactNode } from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';

const ModalContainer = styled.div`
  z-index: 10000000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  /* .overlay{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
  } */
  .modal{
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    /* min-height: 200px;
    min-width: 200px; */
  }

`;
type ModalProps = {
  children: ReactNode;
}

const Modal = ({children}:ModalProps) => {
    return createPortal(
        <ModalContainer>
          {children} 
        </ModalContainer>,
        document.body
    )
}

export default Modal
