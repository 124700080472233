import React, {useState, useEffect, useContext, createContext, ReactNode} from 'react'

const AuthContext = createContext<any>(null)


export const AuthContextProvider = ({children}: {children: ReactNode}) => {
    const [isAuth, setIsAuth] = useState(false)
    const [urlPath, setUrlPath] = useState("")
    const login = () => setIsAuth(true)
    const logout = () => {
      window.localStorage.removeItem("token");
      setIsAuth(false)
    }

    useEffect(() => {
      (() => {
        const token = window.localStorage.getItem("token")
        if(token){
          login()
        }
        else{
          logout()
        }
      })()
    }, [])
    
    return (
      <AuthContext.Provider value={{isAuth, login, logout, setIsAuth, urlPath, setUrlPath}}>
        {children}
      </AuthContext.Provider>
    )
}

const useAuth = () => useContext(AuthContext)

export default useAuth
