import FeatherIcon from 'feather-icons-react';

type IconProps = {
  icon: string;
  stroke?: string;
  fill?: string;
  size?: string;
  clickHandler?: () => void;
}
export default function Icons({icon, stroke, fill, size, clickHandler}: IconProps) {
  const clicker = () => {
    if(clickHandler){
      clickHandler()
    }
    return
  }
  return(
    <div onClick={clicker}>
      <FeatherIcon icon={icon} stroke={stroke || "white"} size={size || "15"} fill={fill || null}/>
    </div>
  )
}