import React from 'react';
import {ThemeProvider} from "styled-components";
import { QueryClient, QueryClientProvider} from 'react-query'
import {ToastProvider} from 'react-toast-notifications';
import {AuthContextProvider} from './context'
// import { Provider as ReduxProvider } from 'react-redux'
// import { store } from './redux/store'
import {GlobalStyle, theme} from './config/style'
import Approutes from './Approutes'

const queryClient = new QueryClient()

const App = () => {
  return(
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <QueryClientProvider client={queryClient}>
          {/* <ReduxProvider store={store}> */}
            <ToastProvider autoDismiss={true} placement='bottom-right'>
              <Approutes />
            </ToastProvider>
          {/* </ReduxProvider> */}
          </QueryClientProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </>
  )
}

export default App;
