import styled from "styled-components";

export const BreadCrumbStyle = styled.div`
  width: 72%;
  align-self: center;
  display: flex;
  align-items: center;
  a, p{
    color: #ee2564;
    font-size: 12px;
  }
  i{
    color: #FFFFFF;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 10px 20px;
  }
`;