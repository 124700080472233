import styled from 'styled-components';
import {NavLink} from "react-router-dom";

const ExploreContainer = styled.div<{ columnCount: number, isSmall: boolean, rightMargin: string, }>`
 width: max-content;
 /* margin-right: 40px; */
 margin-right: ${props => props.rightMargin};
  h1{
    font-size: ${({isSmall}) => isSmall ? "16px" : "18px"};
    font-weight: 500;
    line-height: ${({isSmall}) => isSmall ? "18px" : "12px"};
    color: ${({theme:{colors}}) => colors.whitely};
  }
  .list{
    margin-top: 20px;
    column-count: ${({columnCount}) => columnCount};
    a{
    display: block;
    font-size: ${({isSmall}) => isSmall ? "18px" : "14px"};
    font-weight: 300;
    line-height: ${({isSmall}) => isSmall ? "16px" : "33px"};
    color: ${({theme:{colors}}) => colors.whitely};
    }
  }
`;

type ExploreProps = {
    header: string;
    list: string[];
    isSmall?: boolean;
    columnCount?: number;
    rightMargin?: number;
  }


const Explore = ({header, list, columnCount, isSmall, rightMargin}: ExploreProps) => {
  return (
    <ExploreContainer columnCount={columnCount || 1} isSmall={isSmall || false} rightMargin={rightMargin ? `${rightMargin}px` : '40px'}>
      <h1>{header}</h1>
      <div className="list">
        {
          list.map(item => (
            <NavLink to={`/explore?category=${item}`} key={item}>{item}</NavLink>
          ))
        }
      </div>
    </ExploreContainer>
  )
}

export default Explore