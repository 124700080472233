import styled from 'styled-components';

export const SmallNav = styled.nav<{isTransparent?: boolean, navPosition?: string}>`
  width: 100%;
  height: 50px;
  padding: 0 20px;
  /* position: sticky; */
  top: 0;
  position: ${({navPosition}) => navPosition || 'sticky'};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background: linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%); */
  background: ${({isTransparent, theme}) => isTransparent ? 'transparent': theme.colors.background};
  z-index: 10;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;
export const LargeNav = styled.nav<{isTransparent?: boolean, navPosition?: string}>`
  width: 100%;
  height: 80px;
  padding: 0 50px;
  /* position: sticky; */
  position: ${({navPosition}) => navPosition || 'sticky'};
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background: linear-gradient(125deg, #91C7FA -9.95%, #9658FF 104.9%); */
  background: ${({isTransparent, theme}) => isTransparent ? 'transparent': theme.colors.background};
  z-index: 10;
  @media(max-width: 767px) {
    display: none;
  }
`;

export const FirstPart = styled.div<{ isExplore: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  .explore{
    position: relative;
    margin-left: 80px;
    h3{
      cursor: pointer;
      color: ${({theme:{colors}}) => colors.white};
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
    div.allExplores{
      display: ${({isExplore}) => isExplore ? "flex" : "none"};
      flex-direction: row;
      background-color: ${({theme:{colors}}) => colors.background};
      position: absolute;
      top: 40px;
      padding: 35px;
      ::before{
        position: absolute;
        /* height: 0;
        width: 0; */
        top: -15px;
        left: 0px;
        content: "";
        border-style: solid;
        border-width: 0 10px 15px 10px;
        border-color: transparent transparent ${({theme:{colors}}) => colors.background} transparent;
      }
    }
  }
`;

export const LastPart = styled.div`
  display: flex;
  align-items: center;
`;

export const Search = styled.div<{fullMobileSearch?:boolean}>`
  margin: 0 30px 0 0;
  cursor: pointer;
  position: relative;
  svg{
    margin: 5px 0 0;
  }
  .searchBar{
    display: flex;
    flex-direction: row;
    height: 45px;
    min-width: 250px;
    width: 35vw;
    max-width: 600px;
    padding: 0 32px;
    align-items: center;
    background-color: rgba(147, 155, 252, 0.38);
    border-radius: 45px;
    input{
        background-color: transparent;
        border: none;
        margin-left: 20px;
        width: 100%;
        color: ${({theme:{colors}}) => colors.white};
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
        line-height: 18px;
        ::placeholder{
          color: ${({theme:{colors}}) => colors.white};
        }
    }
  }
  .searchResult{
    display: flex;
    flex-direction: column;
    position: absolute;
    min-width: 250px;
    width: 35vw;
    max-width: 600px;
    padding: 0 20px;
    margin: 5px 0 0;
    align-items: center;
    a{
      background-color: transparent;
      text-decoration: none;
      outline: none;
      cursor: default;
      width: 100%;
      .talent{
        display: flex;
        flex-direction: row;
        height: 50px;
        width: 100%;
        background-color: ${({theme:{colors}}) => colors.deepPurple};
        margin: 0 0 1px;
        padding: 0 10px;
        align-items: center;
        img{
          height: 40px;
          width: 40px;
          border-radius: 40px;
          margin: 0 15px 0 0;
        }
        p{
          color: ${({theme:{colors}}) => colors.grey4};
          font-size: 13px;
        }
      }
      &:hover, &:visited, &:active, &:link, &::selection, &::-moz-selection{
        background-color: transparent;
        text-decoration: none;
        outline: none;
        cursor: default;
      }
    }
  }
  @media(max-width: 767px) {
    margin: 0;
    width: ${({fullMobileSearch}) => fullMobileSearch && '100%'};
    .searchIcon{
      width: 100%;
    }
    .searchResult, .searchBar{
      width: 100%;
    }
  }
`;
