import styled from 'styled-components'

export const Form = styled.form`
  padding: 20px;
  margin: 20px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 650px){
    width: 80%;
  }
  .unit{
    width: 100%;
    margin: 0 0 15px;
    /* border: 2px solid red; */
    label{
        color: ${props => props.theme.colors.white};
        margin: 0 0 20px;
        font-size: 14px;
    }
    .inner{
        border: thin solid ${props => props.theme.colors.greyD7};
        border-radius: 10px;
        padding: 0 ;
        margin: 5px 0 0;
        display: flex;
        align-items: center;
        input{
          background-color: ${props => props.theme.colors.newPurple};
          padding: 0 20px;
          border: none;
          height: 50px;
          width: 100%;
          color: ${props => props.theme.colors.white};
          border-radius: 10px;
          font-size: 14px;
        }
        svg{
          margin: 5px 0 0 0;
        }
      }
      .innerWithPadding{
        padding: 0 20px 0 0;
      }
      span{
        color: red;
        font-size: 10px;
        position: relative;
        top: -10px;
      }
    }
    a.side{
      color: ${props => props.theme.colors.white};
      align-self: flex-end;
      font-size: 12px;
    }
    input[type=submit], button{
      background-image: linear-gradient(52.96deg, #3F7DFE 10.7%, #B183FF 92%);
      width: 100%;
      height: 50px;
      color: ${props => props.theme.colors.white};
      border-radius: 10px;
      font-size: 14px;
      border: none;
      align-self: center;
      cursor: pointer;
      margin: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .clickabout{
      display: flex;
      align-items: center;
      p{
        font-size: 12px;
        margin: 0 5px 0 0;
        color: ${props => props.theme.colors.whitely};
      }
      a{
        font-size: 16px;
        color: ${props => props.theme.colors.purple};
      }
    }
`;