import React, {useState} from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {NavLink} from 'react-router-dom'
import { useToasts } from 'react-toast-notifications';
import AuthWrap from './AuthForm';
import {Icons, Spinner} from '../../components'
import {Form} from './AuthStyles'
import {useAuth} from '../../context'
import {baseApi} from '../../module'

type Inputs = {
    email: string,
    username: string,
    lastName: string,
    firstName: string,
    dob: string,
    password: string,
};

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  username: yup.string().required(),
  dob: yup.date().required(),
  password: yup.string().required().min(8).matches(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/)
});

const Signup = () => {
    const {login} = useAuth()
    const { addToast } = useToasts();
    const [showPassword, setShowPassword] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
      resolver: yupResolver(schema)
    });
    const onSubmit: SubmitHandler<Inputs> = async(formData) => {
    console.log(formData)
    setIsLoading(true);
    // return
    try {
      const {data, status} = await baseApi.post('/auth/register', formData);
      if(status === 200){
        window.localStorage.setItem("token", JSON.stringify(data.data.token));
        login()
        return
      }
      if(status === 400){
        if(data.errors.length > 1){
          addToast('username & email taken', { appearance: 'info' });
        }else if(data.errors[0].msg === "Email already exist"){
          addToast('email taken', { appearance: 'info' });
        }else if(data.errors[0].msg === "Username taken already"){
          addToast('Username taken', { appearance: 'info' });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);

    return (
          <AuthWrap
            header= "Create an account today"
            subHeader= "Create and account and get a video from your favourite celebrity"
          >
          <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="unit">
                <label>Firstname</label>
                <div className="inner">
                  <input placeholder="Firstname" type="text" {...register("firstName")} />
                </div>
                {errors.firstName && <span>invalid format</span>}
              </div>
              <div className="unit">
                <label>Lastname</label>
                <div className="inner">
                  <input type="text" placeholder="Lastname" {...register("lastName")} />
                </div>
                {errors.lastName && <span>invalid format</span>}
              </div>
              <div className="unit">
                <label>Email</label>
                <div className="inner">
                  <input type="email" placeholder="Email" {...register("email")} />
                </div>
                {errors.email && <span>invalid format</span>}
              </div>
              <div className="unit">
                <label>Username</label>
                <div className="inner">
                  <input type="text" placeholder="Username" {...register("username")} />
                </div>
                {errors.username && <span>invalid format</span>}
              </div>
              <div className="unit">
                <label>Birthday</label>
                <div className="inner">
                  <input type="date" {...register("dob")} />
                </div>
                {errors.username && <span>invalid format</span>}
              </div>
              <div className="unit">
                <label>Password</label>
                <div className="inner innerWithPadding">
                  <input type={showPassword ? "text" : "password"} placeholder="Password" {...register("password")} />
                  <Icons icon={showPassword ? "eye-off" : "eye"} clickHandler={toggleShowPassword} size="20"/>
                </div>
                {errors.password && <span>invalid password format</span>}
              </div>
              {
                !isLoading ? (<input type="submit" value="Signup"/>) : (
                  <button>
                    <Spinner />
                  </button>
                )
              }
              <div className="clickabout">
                <p>Already have an account?</p>
                <NavLink to="/login" className="side">login</NavLink>
              </div>
            </Form>
        </AuthWrap>
    )
}

export default Signup
