import styled from 'styled-components';

export const FooterContainer = styled.div`
  width: 100%;
  min-height: 333px;
  background: #1A033F;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .main{
    display: flex;
    height: 100%;
    padding: 60px 80px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .content{
      display: flex;
    }
    .subscribe{
      width: 80vw;
      max-width: 400px;
      margin-left: 20px;
      p{
        font-size: 16px;
        font-weight: 500;
        color: ${(props) => props.theme.colors.white};
      }
      form{
        height: 50px;
        display: flex;
        align-items: center;
        margin: 25px 0;
        input[type=email]{
          background-color: transparent;
          border: thin solid #DCC8FF;
          color: ${(props) => props.theme.colors.white};
          padding: 0 10px;
          font-size: 14px;
          height: 100%;
          width: 70%;
        }
        input[type=submit]{
          cursor: pointer;
          height: 100%;
          width: 30%;
          background-color: ${(props) => props.theme.colors.lilac};
          border: none;
          border-radius: 1px;
          color: ${(props) => props.theme.colors.white};
          font-size: 14px;
          font-weight: 400;
        }
      }
      .socials{
        svg{
          cursor: pointer;
          margin-right: 30px;
        }
      }
    }
    @media only screen and (max-width: 360px) {
      .subscribe{
        form{
          input[type=submit]{
            font-size: 12px;
          }
        }
      }
    }
    @media only screen and (max-width: 767px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      padding: 60px 10vw;
      .content{
        margin-top: 50px;
        align-self: flex-start;
      }
      .subscribe{
        margin-left: 0px;
        form{
          margin: 15px 0;
        }
      }
    }
    @media only screen and (max-width: 1023px) {
      .id{
        display: none;
      }
    }

  }
  .copyright{
    color: ${(props) => props.theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    h3{
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }
`;