import React, { useEffect, useState } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import * as yup from "yup";
import { useFetchRequestId, makePayment } from '../../module'
import { setTimeout } from 'timers';
import { PaystackButton } from 'react-paystack';
import { v4 as uuidv4 } from 'uuid';

type formInputs = {
  [key: string]: string;
};

const schema = yup.object().shape({
  cardNumber: yup.string().required().matches(/^(\d){16}$/),
  expiry: yup.string().required().matches(/^(\d){4}$/),
  cvv: yup.string().required().matches(/^(\d){3}$/),
  pin: yup.string().required().matches(/^(\d){4}$/),
});

const CardPayment = ({ match, setPaymentInitialized, setFlw_ref }: { match: any, setPaymentInitialized: any, setFlw_ref: any }) => {
  const { response, error, loading } = useFetchRequestId(match.params.requestId || "")
  const [isLoading, setIsLoading] = useState(false)
  const [cardError, setCardError] = useState(false);
  const publicKey = "pk_test_86f85beca1c41dea982647b8ea5922190f5ba933"
  // const amount = 1000000 // Remember, set in kobo!
  const [email, setEmail] = useState(response?.data.data.email);
  const [amount, setAmount] = useState(0)
  

  useEffect(() => {
      if(response){
        setEmail(response.data.data.email);
        setAmount(response.data.data.price * 1000)
       
      }
  }, [response])
  
  const initPayment = () =>{
        
        setFlw_ref(uuidv4())
        // console.log(flw_ref)
        setPaymentInitialized(true)
  }

  
  const componentProps = {

    email,
    amount,
    publicKey,
    text: "Pay Now",
    onSuccess: () =>
     initPayment(),
    onClose: () => alert("Wait! You need this oil, don't go!!!!"),

  }

  return (
    <>
      <div className="checkout-form">

       
        <PaystackButton className="paystack-button" {...componentProps} />
      </div>
    </>
  )
}

export default CardPayment
