import React, {useState} from 'react';
import styled from 'styled-components';
import CardPayment from './cardPayment'
import OTP from './otp'
import FLW from '../../assets/paystack.png'

export const PaymantStyle = styled.div`
  background-color: ${props => props.theme.colors.background};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0 5px;
  .content{
    width: 95%;
    max-width: 500px;
    padding: 20px 20px;
    @media (min-width: 500px){
      padding: 20px 50px;
      background-color: ${props => props.theme.colors.newPurple};
    }
    .powered{
      display: flex;
      justify-content: center;
      margin: 0 0 20px;
      h5{
        color: white;
        font-size: 14px;
        font-weight: 400;
      }
      img{
        width: 80px;
        height: 20px;
        margin: 0 0 0 5px;
      }
    }
    h1{
      color: ${props => props.theme.colors.white};
    }
    form, .card-input{
      display: flex;
      flex-direction: column;
      label{
        color: ${props => props.theme.colors.white};
        margin: 5% 0 10px;
        font-size: 14px;
      }
      h3{
        color: ${props => props.theme.colors.white};
        text-align: center;
        /* margin: 5% 0 10px; */
        font-size: 14px;
      }
      p{
        color: #2F92ED;
        font-size: 12px;
        margin: 10px 0;
      }
      input, select, textarea{
        background-color: transparent;
        color: ${props => props.theme.colors.white};
        border: thin solid #96909E;
        border-radius: 10px;
        font-size: 14px;
      }
      input, select, button{
        height: 50px;
        padding: 0 20px;
      }
      textarea{
          padding: 10px 20px 0;
      }
      input[type=submit], button{
        background-image: linear-gradient(52.96deg, #3F7DFE 10.7%, #B183FF 92%);
        width: 80%;
        border: none;
        border-radius: 10px;
        align-self: center;
        cursor: pointer;
        margin-top: 50px;
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
      span{
        color: red;
        font-size: 10px;
      }
    }
    .card-input{
      margin: 10px 0 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 10px;
      .unit{
        width: 100%;
        input{
          width: 100%;
        }
      }
    }
  }
`;
// export const PaymantStyle = styled.div`
//   background-color: ${props => props.theme.colors.background};
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 100vh;
//   padding: 0 40px;
//   .content{
//     max-width: 600px;
//     background-color: ${props => props.theme.colors.deepPurple};
//     padding: 50px 20px;
//     @media (min-width: 400px){
//       padding: 50px 50px;
//     }
//     h1{
//       color: ${props => props.theme.colors.white};
//     }
//     form, .card-input{
//       display: flex;
//       flex-direction: column;
//       label{
//         color: ${props => props.theme.colors.white};
//         margin: 5% 0 10px;
//         font-size: calc(0.5625rem + ((1vw - 3px) * 3));
//         @media (min-width: 600px){
//           font-size: 18px;
//           margin: 60px 0 0;
//         }
//       }
//       input, select, textarea{
//         background-color: transparent;
//         color: ${props => props.theme.colors.white};
//         border: thin solid #96909E;
//         border-radius: 15px;
//         /* margin: 0 0 5%; */
//         font-size: calc(0.5625rem + ((1vw - 3px) * 3));
//         @media (min-width: 600px){
//           font-size: 18px;
//           /* margin: 0 0 60px; */
//         }
//       }
//       input, select, button{
//         height: 10vw;
//         max-height: 60px;
//         padding: 0 20px;
//       }
//       textarea{
//           padding: 10px 20px 0;
//       }
//       input[type=submit], button{
//         background-image: linear-gradient(52.96deg, #3F7DFE 10.7%, #B183FF 92%);
//         width: 80%;
//         border-radius: 10px;
//         align-self: center;
//         cursor: pointer;
//         margin-top: 10%;
//       }
//       /* Chrome, Safari, Edge, Opera */
//       input::-webkit-outer-spin-button,
//       input::-webkit-inner-spin-button {
//         -webkit-appearance: none;
//         margin: 0;
//       }

//       /* Firefox */
//       input[type=number] {
//         -moz-appearance: textfield;
//       }
//       span{
//         color: red;
//         font-size: 10px;
//       }
//     }
//     .card-input{
//       margin: 10px 0 0;
//       display: grid;
//       grid-template-columns: 1fr 1fr 1fr;
//       grid-column-gap: 10px;
//       .unit{
//         width: 100%;
//         input{
//           width: 100%;
//         }
//       }
//     }
//   }
// `;

const Payment = ({match}: {match: any}) => {
  const [paymentInitialized, setPaymentInitialized] = useState(false)
  const [flw_ref, setFlw_ref] = useState("")
  
  return(
    <PaymantStyle>
      <div className="content">
        <div className="powered">
          <h5>Powered by </h5>
          <img src={FLW} alt="paystack" />
        </div>
        {
          !paymentInitialized ? (
            <CardPayment match={match} setFlw_ref={setFlw_ref} setPaymentInitialized={setPaymentInitialized}/>
          ) : (
            <OTP flw_ref={flw_ref} requestId={match.params.requestId}/>
          )
        }
      </div>
    </PaymantStyle>
  )
}

export default Payment
